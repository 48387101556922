$surface1: #15344A;
$surface2: #E6E6E6;
$surface3: #EEEEEE;
$surface4: #D5DCE0;
$surface5: #F4E7DF;
$black: #111111;
$grey1: #2C2C2C;
$grey2: #414141;
$grey3: #565656;
$grey4: #929292;
$grey5: #BCBCBC;
$accent1: #CD4232;
$accent2: #E88E3D;