.news-bar {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    border-radius: 4px;
    margin-top: $sectionMarginMobile;
    margin-bottom: $sectionMarginMobile;
    background-color: $surface2;

    &-container {
        position: relative;

        >.more {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 105px;
            background: transparent;
            background: -moz-linear-gradient(top, transparent 0%, rgba(217, 217, 217, .7) 50%, rgba(217, 217, 217, 1) 100%);
            background: -webkit-linear-gradient(top, transparent 0%, rgba(217, 217, 217, .7) 50%, rgba(217, 217, 217, 1) 100%);
            background: linear-gradient(to bottom, transparent 0%, rgba(217, 217, 217, .7) 50%, rgba(217, 217, 217, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='rgba(217,217,217,1)', GradientType=0);
            cursor: pointer;
            opacity: 1;
            transition: all 0.1s;

            &:after {
                position: absolute;
                width: 12px;
                height: 8px;
                bottom: 15px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url('/assets/images/chevron_down.svg');
                background-position: center center;
                background-repeat: no-repeat;
                content: '';
            }

            &[data-direction="up"] {
                z-index: 2;
                top: 0;
                bottom: auto;
                height: 105px;
                background: rgba(217, 217, 217, 1);
                background: -moz-linear-gradient(top, rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, .7) 50%, transparent 100%);
                background: -webkit-linear-gradient(top, rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, .7) 50%, transparent 100%);
                background: linear-gradient(to bottom, rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, .7) 50%, transparent 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(217,217,217,1)', endColorstr='transparent', GradientType=0);

                &:after {
                    top: 20px;
                    transform: scaleY(-1);
                }
            }

            &.orange {
                &:after {
                    width: 26px;
                    height: 14px;
                    background-image: url('/assets/images/angle_down.svg');
                    background-size: contain;
                }
            }
        }
    }

    &-content {
        position: relative;
        overflow: auto;
        max-height: 80vh;

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba(188, 188, 188, .5);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(188, 188, 188, .8);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(188, 188, 188, 1);
        }

        &.open {
            >.more {
                display: none;
            }
        }

        ul {
            padding: 0 16px;
            list-style: none;

            >li {
                display: block;
                padding: 15px 0;

                >a {
                    display: inline-block;
                    text-decoration: none;
                    color: $black;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0.011em;
                    >span.news-bar-link-time {
                        color: #797979;
                    }

                    >span.news-bar-link-text {
                        padding-left: 5px;
                    }

                    &:hover {
                        >span.news-bar-link-text {
                            text-decoration: underline;
                        }
                    }

                    &.active {
                        >span.news-bar-link-text {
                            color: #e58c48;
                        }
                    }
                }

                +li {
                    border-top: 1px solid $grey5;
                }

                @media(min-width:992px) {
                    padding: 10px 16px;

                    &:first-child {
                        padding-top: 15px;
                    }
                }
            }

            &.item-list {
                padding-top: 7px;
                text-indent: -7px;
    

                >li {
                    padding: 7px 16px;

                    >a {
                        span {
                            padding-left: 7px;
                            color: $surface1;
                            font-size: 1.125rem;
                            font-weight: $fwMediumBold;
                            line-height: 24px;
                            letter-spacing: 0.04em;
                            text-transform: uppercase;
                        }
                    }

                    +li {
                        border-top: none;
                    }
                }
            }

            @media(min-width:992px) {
                padding: 0;
            }
           
                
            
        }

        @media(min-width:576px) {
            max-height: 401px;
        }

        @media(min-width:992px) {
            max-height: 712px;
        }

        @media(min-width:1070px) {
            max-height: 672px;
        }
    }

    .news-bar-sort {
        ul {
            display: flex;
            padding: 16px 12px 0;
            margin: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.11);
            flex-direction: column;
            justify-content: space-between;
            list-style: none;

            li {
                .nav-link {
                    margin-bottom: 16px;
                    padding: 0 12px;
                    border: none;
                    background: transparent;
                    color: $grey4;
                    font-weight: $fwBold;
                    font-size: 1.125rem;
                    line-height: 18px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    cursor: pointer;

                    &.active {
                        border: none;
                        background: transparent;
                        color: $accent2;
                    }
                }
            }
        }
    }
    &:not(.scrolled) {
        .more[data-direction="up"] {
            pointer-events: none;
            opacity: 0;
        }
    }

    &.scroll-end {
        .more[data-direction="down"] {
            pointer-events: none;
            opacity: 0 !important;
        }
    }
    .hidden{
        pointer-events: none;
        opacity: 0;
    }
 

    @media(min-width:576px) {
        margin-bottom: 0;
    }

    @media(min-width:650px) {
        margin-top: $sectionMargin;
    }

    @media(min-width:992px) {
        margin-top: 0;
    }
    @media screen and (max-width: 768px) {
        &:not(.scrolled) {
            .more[data-direction="up"] {
                pointer-events: none;
               display: none;
            }
        }
    
        &.scroll-end {
            .more[data-direction="down"] {
                pointer-events: none;
                display: none;
            }
        }
        
    }
 

    &.news-carousel {
        
        //     .news-bar-content {
        //         ul {
        //             >li {
        //                 +li {
        //                     @media(max-width:991px) {
        //                         border-left: 1px solid #BCBCBC;
        //                         border-top: none;
        //                     }
        //                 }

        //                 @media(max-width:991px) {
        //                     width: calc(100% / 2.1);
        //                     padding-left: 15px;
        //                     padding-right: 15px;
        //                     flex: 0 0 auto;
        //                 }

        //                 @media(max-width:480px) {
        //                     width: calc(100% / 1.4);
        //                     max-height: 100px;
        //                     overflow: hidden;
        //                     > a{
        //                         >span.news-bar-link-text {
        //                             font-size: .9rem;
        //                             line-height: 17px;
        //                         }
        //                         >span.news-bar-link-time {
        //                             font-size: .9rem;
        //                             line-height: 17px;
        //                         }
        //                     }
        //                 }
        //             }

        //             @media(max-width:991px) {
        //                 display: flex;
        //                 padding-top: 5px;
        //                 margin-bottom: 5px;
        //                 padding-left: 0;
        //                 flex-wrap: nowrap;
        //                 flex-direction: row;
        //             }
        //         }
        //     }
        // }

        // @media(max-width:991px) {
        //     margin-bottom: 0;
        // }
    }

    &.news-bar-long {
        .news-bar-content {
            @media(min-width:992px) {
                max-height: 890px;
            }

            @media(min-width:1090px) {
                max-height: 848px;
            }

            @media(min-width:1240px) {
                max-height: 826px;
            }
        }
    }
}

.news-bar#television-news-bar-1,
.news-bar#television-news-bar-2 {
    display: block;
    margin-top: 0;

    .news-bar-container {
        .news-bar-content {
            @media(min-width:992px) {
                max-height: 458px;
                padding: 0 16px;
            }
        }
    }

    @media(min-width:650px) {
        /*margin-top: $sectionMargin;*/
    }

    @media(min-width:992px) {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.news-bar#television-news-bar-1.sm-horizontal,
.news-bar#television-news-bar-2.sm-horizontal {
    .news-bar-sort {
        ul {
            @media(min-width:576px) {
                flex-direction: row;
                justify-content: flex-start;
            }
        }
    }

    .news-bar-container {
        .news-bar-content {
            ul {
                >li {
                    @media(min-width:576px) and (max-width:991px) {
                        width: fit-content;
                    }
                }
            }
        }
    }
}
// ----------------------------Mobile Version Started for NewsFeed---------------------------



@media (min-width: 577px) {
    .news-carousel{
       display: none;
    }
}

@media (max-width: 576px) {
 .news-bar {
    display: none;
 }
    
}
.hide_in_mac{
    display: none;
}
