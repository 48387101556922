.main-banner {
    position: relative;
    display: block;
    height: 280px;
    overflow: hidden;
    border-radius: 5px;

    @media(min-width:650px) {
        height: 440px;
    }
    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        content: '';
    }

    >img {
        position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%,-50%);

        @media(min-width:576px) {
            &.img-xs {
                display: none;
            }

            &.img-sm {
                display: block;
            }
        }

        @media(min-width:992px) {
            &.img-sm {
                display: none;
            }
        }
    }

    >.caption {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 20px;

        >.caption-category {
            margin-bottom: 8px;
            color: $accent2;
            font-size: 0.875rem;
            font-weight: $fwBold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        >.caption-header {
            margin-bottom: 8px;
            color: #fff;
            font-size: 1.5rem;
            font-weight: $fwBold;
            line-height: 32px;

            @media(min-width:650px) {
                font-size: 2.25rem;
                line-height: 38px;
            }
        }

        >.caption-text,
        >.caption-text * {
            margin-bottom: 16px;
            color: #fff;
            font-family: $serifPro;
            font-size: 1.125rem;
            line-height: 22px;
        }

        .caption-text * {
            margin: 0;
            padding: 0;
        }

        >.caption-date {
            color: $grey4;
            font-size: 0.8125rem;
            line-height: 12px;
            letter-spacing: 0.04em;
        }
    }
}

.banner-special-projects {
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;
    padding: 456px 104px 0;
    border-radius: 4px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    .bottom-link {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }

    &-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;

        >img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
            @media(max-width:359px) {
                left: 50%;
                width: auto;
                height: 100%;
                max-width: unset;
                transform: translateX(-50%);
            }
        }

        &:not(.multi-images)>img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
        }

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
            content: '';
        }

        @media(max-width:359px) {
            bottom: 0;
        }
    }

    a {
        text-decoration: none;
    }

    &-caption {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        .special-projects-main {
            display: block;
            width: 100%;

            &-label {
                margin-bottom: 8px;
                color: #E88E3D;
                text-align: center;
                font-size: 0.875rem;
                font-weight: $fwBold;
                line-height: 14px;
                text-transform: uppercase;
                letter-spacing: 0.05em;

                @media(min-width:992px) {
                    margin-bottom: 14px;
                }
            }

            &-title {
                margin-bottom: 8px;
                padding: 0 22px;
                color: #fff;
                font-weight: $fwMediumBold;
                font-size: 1.5rem;
                text-align: center;
                line-height: 24px;

                @media(min-width:450px) {
                    font-size: 2rem;
                    line-height: 32px;
                }
                @media(min-width:992px) {
                    font-size: 2.25rem;
                    margin-bottom: 14px;
                    line-height: 38px;
                }
            }

            &-subtitle {
                padding: 0 22px;
                color: #fff;
                font-family: $serifPro;
                font-size: 1.125rem;
                font-weight: 400;
                text-align: center;
                line-height: 22px;
            }

            >.center-content {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
            }
        }

        .special-projects-slider {
            position: relative;
            display: block;
            width: 100%;
            margin: 16px auto 0;
            padding: 0 24px 32px;

            &:before {
                position: absolute;
                top: 0;
                left: 24px;
                right: 24px;
                height: 1px;
                background: $grey4;
                content: '';

                @media(min-width:992px) {
                    left: 85.5px;
                    right: 85.5px;
                }
            }

            .swiper {
                .swiper-slide {
                    align-items: flex-start;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                top: calc(50% - 16px);
                padding: 43px;
                transform: translateY(-50%);
                cursor: pointer;
            }

            .swiper-button-prev {

                left: 0;
                background: url('/assets/images/arrow_left.svg') no-repeat center center transparent;
            }

            .swiper-button-next {

                right: 0;
                background: url('/assets/images/arrow_right.svg') no-repeat center center transparent;
            }

            .slide-left,
            .slide-right {
                display: none;
                position: absolute;
                top: calc(50% - 16px);
                padding: 43px;
                transform: translateY(-50%);
                cursor: pointer;
            }

            .slide-left {
                left: 0;
                background: url('/assets/images/arrow_left.svg') no-repeat center center transparent;

                @media(min-width:992px) {
                    display: block;
                }
            }

            .slide-right {
                right: 0;
                background: url('/assets/images/arrow_right.svg') no-repeat center center transparent;

                @media(min-width:992px) {
                    display: block;
                }
            }

            &-inner {
                display: flex;
                overflow: hidden;
                margin-left: -16px;
                margin-right: -24px;
                padding-top: 16px;
                flex-direction: row;
                flex-wrap: nowrap;

                .slider-item {
                    display: inline-block;
                    padding: 8px 16px;
                    // flex: 0 0 calc(100% / 1.5);
                    color: #fff;
                    text-align: left;

                    .slide-title {
                        font-size: 1.125rem;
                        font-weight: $fwBold;
                        line-height: 22px;
                        letter-spacing: -0.01em;
                    }

                    .slide-description {
                        margin-top: 4px;
                        font-family: $serifPro;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                    }

                    @media(min-width:650px) {
                        // flex: 0 0 calc(100% / 2.2);
                    }

                    @media(min-width:992px) {
                        // flex: 0 0 calc(100% / 3);
                    }

                    @media(max-width:359px) {
                        padding-right: 0;
                        // flex: 0 0 calc(100% / 1.2);
                    }
                }
            }

            &.scroll-start {
                .slide-left {
                    display: none;
                }
            }

            &.scroll-end {
                .slide-right {
                    display: none;
                }
            }

            @media(min-width:992px) {
                padding-left: 85.5px;
                padding-right: 85.5px;
                padding-bottom: 32px;
            }

            @media(max-width:359px) {
                padding-bottom: 10px;
            }
        }

    }

    &.no-slider {
        padding: 377px 0 0;

        .banner-special-projects-caption {
            bottom: 0;
            top: 0;

            .special-projects-main {
                height: 100%;

                .special-projects-main-title {
                    line-height: 38px;
                }
            }
        }

        @media(min-width:992px) {
            padding-top: 377px;
        }
    }

    &.with-bottom-link {
        .special-projects-slider {
            padding-bottom: 80px;
        }
    }

    @media(min-width:992px) {
        padding-top: 456px;
    }

    @media(max-width:309px) {
        padding-top: 200%;
    }

    @media(max-width:262px) {
        padding-top: 220%;
    }
}

.long-read-banner {
    position: relative;
    margin-top: $subHeaderMarginTop;
    padding: 0;

    &-content {
        position: relative;
    }

    &__background {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 602px;

        img {
            position: absolute;
            top: 0;
            left: 50%;
            width: auto;
            max-width: unset;
            max-height: unset;
            min-height: 100%;
            min-width: 100%;
            transform: translateX(-50%);
        }

        &:after {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
            content: '';
        }

        /*        @media(min-width:650px){
                    padding-top: 632px;
                }*/
        @media(min-width:992px) {
            padding-top: 744px;
        }
    }

    &__caption {
        position: absolute;
        z-index: 2;
        bottom: 2%;
        left: 50%;
        right: 0;
        width: 100%;
        max-width: 1026px;
        padding: 0 20px;
        transform: translateX(-50%);

        &__label {
            margin-bottom: 16px;
            color: $accent1;
            font-weight: $fwBold;
            font-size: 1rem;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        &__title {
            margin-bottom: 16px;
            color: #FFFFFF;
            font-weight: $fwBold;
            font-size: 2.75rem;
            line-height: 48px;
            text-align: center;

            @media(min-width:650px) {
                font-size: 3rem;
                line-height: 56px;
            }

            @media(min-width:992px) {
                font-size: 3.5rem;
                line-height: 64px;
            }
        }

        &__description {
            margin-bottom: 16px;
            color: #FFFFFF;
            font-family: $serifPro;
            font-weight: 400;
            font-size: 1.375rem;
            line-height: 28px;
            text-align: center;

            @media(min-width:650px) {
                font-size: 1.5rem;
            }
        }

        &__date {
            color: $grey4;
            font-weight: 400;
            font-size: 0.8125rem;
            line-height: 12px;
            letter-spacing: 0.04em;
            text-align: center;

            .time {
                display: inline-block;
                margin-left: 8px;
                padding-left: 8px;
                border-left: 1px solid;
            }
        }

        @media(min-width:362px) {
            bottom: 6%;
        }

        @media(min-width:650px) {
            bottom: 15%;
        }

        @media(min-width:992px) {
            bottom: 35%;
        }

        /*        @media(min-width:992px){
                    transform: translate(-50%, -50%);
                }*/
    }

    &__description {
        position: relative;
        padding: 20px 32px;
        background: $surface1;

        ul {
            display: flex;
            // max-width: 244px;
            margin: 0 auto;
            padding: 0 12px;
            flex-direction: column;
            list-style: none;

            li {
                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;

                    span:first-child {
                        margin-right: 8px;
                        color: $accent2;
                        font-family: $firaSansExtra;
                        font-weight: 300;
                        font-size: 3.125rem;
                        line-height: 44px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                    }

                    span:last-child {
                        color: $surface2;
                        font-family: $firaSans;
                        font-weight: 800;
                        font-size: 1rem;
                        line-height: 22px;
                        letter-spacing: 0.011em;
                    }
                }

                margin-bottom: 22px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

            @media(min-width:576px) {
                max-width: 1024px;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    margin-left: 8px;
                    margin-right: 8px;
                    margin-bottom: 0;
                    width: calc(25% - 16px);
                    a{
                        align-items: flex-start;
                    }

                    &:nth-child(n+5) {
                        margin-top: 22px;
                    }
                }
            }

            @media(min-width:992px) {
                padding-top: 15px;
                justify-content: center;

                li {
                    max-width: 234px;
                    margin-bottom: 22px;
                    margin-top: 0;

                    &:nth-child(n+5) {
                        margin-top: 0;
                    }
                }
            }
        }

        @media(min-width:992px) {
            position: absolute;
            padding: 24px 25px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3;
            background: rgba(21, 52, 74, 0.7);
        }
    }
}
.caption-category-link {
    margin-bottom: 8px;
    color: $accent2;
    font-size: 0.875rem;
    font-weight: $fwBold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-decoration: none;
    // text-decoration-style: none;
}
.caption-category-link:hover {
    color: $accent2;
}