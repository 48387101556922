.article__gallery {
    position: relative;
    width: 100vw;
    left: 50%;
    margin-bottom: $sectionMarginMobile;
    padding: 33px 0 33px;
    transform: translateX(-50%);
    background: transparent;

    .glide {
        position: relative;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        * {
            -webkit-box-sizing: inherit;
            box-sizing: inherit;
        }

        &__track {
            overflow: hidden;
        }

        &__slides {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            overflow: hidden;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -ms-touch-action: pan-Y;
            touch-action: pan-Y;
            white-space: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            will-change: transform;

            .glide__slide {
                .article__gallery__image {
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 57px;
                    border-radius: 0;
                    text-align: center;

                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: rgba(255, 255, 255, .7);
                        content: '';
                    }

                    @media(min-width:650px) {
                        border-radius: 4px;
                        margin-bottom: 40px;
                    }
                }

                .article__gallery_label {
                    opacity: 0;
                    transition: all 0.3s;
                }

                &.glide__slide--active {
                    .article__gallery__image {
                        &:after {
                            display: none;
                        }
                    }

                    .article__gallery_label {
                        padding: 0 23px;
                        color: #414141;
                        font-family: $inter;
                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 21px;
                        letter-spacing: 0.01em;
                        text-align: center;
                        white-space: normal;
                        opacity: 1;

                        @media(min-width:650px) {
                            padding: 0;
                            font-size: 0.875rem;
                            line-height: 28px;
                            letter-spacing: 0.02em;
                        }

                        @media(min-width:992px) {
                            font-size: 1rem;
                            line-height: 21px;
                            letter-spacing: 0.011em;
                        }
                    }
                }
            }
        }

        &__bullets {
            position: absolute;
            // top: 60vw;
            left: 50%;
            transform: translateX(-50%);

            @media(min-width:650px) {
                // top: calc(var(--galeryImageHeight) + 10px);
                bottom: -23px;
            }

            button {
                display: inline-block;
                width: 5px;
                height: 5px;
                padding: 0;
                margin: 0 10px;
                border: none;
                border-radius: 50%;
                background: #C4C4C4;

                &.glide__bullet--active {
                    width: 7px;
                    height: 7px;
                    background: $accent2;
                }
            }
        }

        &__controls {
            position: absolute;
            left: 50%;
            /*top: 30%;*/
            // top: calc(var(--galeryImageHeight) / 2);
            top: 50%;
            display: flex;
            width: 100%;
            padding: 0 22px;
            justify-content: space-between;
            transform: translate(-50%, - 50%);
            pointer-events: none;
            @media(min-width:650px) {
                /*top: 33%;*/
                // top: calc(var(--galeryImageHeight) / 2);
                top: 50%;
                padding: 0 37px;
            }

            @media(min-width:992px) {
                padding: 0 0;
                max-width: calc(var(--galeryImageWidth) + 135px);
            }

            button {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                padding: 0;
                border: none;
                border-radius: 50%;
                background: rgba(196, 196, 196, 0.6);
                text-align: center;
                pointer-events: all;
                @media(min-width:650px) {
                    width: 40px;
                    height: 40px;
                    background: #C4C4C4;
                }

                @media(min-width:992px) {
                    width: 50px;
                    height: 50px;
                }

                &:after {
                    position: absolute;
                    top: calc(50% - 2px);
                    left: calc(50% - 2px);
                    width: 5px;
                    height: 5px;
                    border: solid #000;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                    content: '';

                    @media(min-width:650px) {
                        top: calc(50% - 5px);
                        left: calc(50% - 3px);
                        width: 12px;
                        height: 12px;
                    }

                    @media(min-width:992px) {
                        top: calc(50% - 7px);
                        left: calc(50% - 4px);
                        width: 15px;
                        height: 15px;
                    }
                }

                +button {
                    &:after {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);

                        @media(min-width:650px) {
                            left: calc(50% - 8px);
                        }

                        @media(min-width:992px) {
                            left: calc(50% - 11px);
                        }
                    }
                }
            }
        }
    }

    @media(min-width:650px) {
        margin-bottom: $sectionMargin;
        background: #fff;
    }
}

.article__mainContent:not(.article-longread) {
    .article__gallery {
        width: 100%;
    }
}

.article {
    &__top {
        margin-bottom: $sectionMarginMobile;
        min-height: 258px;

        .article__header {
            margin-bottom: 20px;

            .article__label {
                padding: 8px 0 $sectionMarginMobile;
                border-top: 1px solid $grey5;
                color: $accent2;
                font-size: 1rem;
                line-height: 20px;
                font-weight: $fwBold;
                letter-spacing: 0.05rem;
                text-transform: uppercase;
                text-decoration: none;

                @media(min-width:650px) {
                    padding-bottom: $sectionMargin;
                }
            }

            .article__title {
                margin-bottom: 16px;
            }

            .article__subtitle {
                max-width: 90%;
                margin-bottom: 16px;
            }

            .article__date {
                color: $grey4;
                font-size: 0.8125rem;
                line-height: 12px;
                letter-spacing: 0.04em;

                .time {
                    display: inline-block;
                    margin-left: 5px;
                    padding-left: 5px;
                    border-left: 1px solid $grey5;

                }
            }
        }

        .article__author {
            position: relative;
            padding-bottom: 16px;

            &:after {
                position: absolute;
                bottom: 0;
                left: 8px;
                right: 8px;
                height: 1px;
                background: $grey5;
                content: '';
            }

            &__name {
                display: block;
                color: $black;
                font-weight: $fwBold;
                font-size: 1.125rem;
                line-height: 21px;
                letter-spacing: 0.011em;
                text-decoration: none;
            }

            &__group {
                color: $black;
                font-weight: 400;
                font-size: 1rem;
                line-height: 21px;
                letter-spacing: 0.011em;
            }

            &__image {
                position: relative;
                z-index: -1;
                right: 0;
                overflow: hidden;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                background: $surface5;

                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                }
            }
        }

        @media(min-width:650px) {
            margin-bottom: $sectionMargin;
        }
    }

    &__mainContent {

        h3 {
            color: $grey1;
            font-size: 1.5rem;
            font-weight: $fwMediumBold;
            line-height: 32px;
        }

        h2 {
            font-weight: $fwBold;

        }

        ul {
            li {
                a {
                    color: $accent2;
                    text-decoration: none;
                }
            }
        }

        figure {
            max-width: 100%;

            img {
                height: auto !important;
            }
        }

        p {
            font-size: 1.125rem;
            font-weight: 400;
            font-family: $serifPro;
            line-height: 30px;

            strong {
                color: inherit;
                font-size: inherit;
                font-weight: $fwMediumBold;
                line-height: inherit;
            }

            b {
                font-family: $sansPro;
                margin-bottom: 24px;
                font-size: 1.375rem;
                font-weight: $fwBold;
                line-height: 32px;

            }

            &:first-child {
                color: $grey1;
                font-size: 24px;
                font-weight: $fwMediumBold;
                line-height: 32px;
                font-family: 'Source Serif Pro';

                strong {
                    color: $grey1;
                    font-size: 24px;
                    font-weight: $fwMediumBold;
                    line-height: 32px;
                    font-family: 'Source Serif Pro';
                }
            }
        }

        .article__gallery.article__gallery__single {
            width: 100%;
            left: unset;
            transform: unset;
        }

        .box--small {
            max-width: 350px;
            min-height: unset;
            margin: 40px auto;
            border-radius: 4px;
            background: $surface2;

            >div {
                &.box__text {
                    padding: 8px;
                }

                &.box__image {
                    position: relative;

                    >img {
                        position: absolute;
                        min-width: 100%;
                        min-height: 100%;
                        max-height: unset;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            @media(min-width:576px) {
                display: flex;
                max-width: 730px;
                margin: 40px auto;
                flex-direction: row;
                flex-wrap: nowrap;

                >div {
                    &.box__image {
                        width: 48%;
                    }

                    &.box__text {
                        position: relative;
                        display: flex;
                        width: 52%;
                        margin-top: 0;
                        padding: 16px 16px 32px;
                        flex-direction: column;

                        .box__textDate {
                            position: absolute;
                            bottom: 16px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        figure.video-container {
            position: relative;
            padding-top: 56%;
            width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .article__transmition {
            margin-bottom: 24px;

            .tab-pane {
                position: relative;
                padding-top: 56%;
                width: 100%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .nav-tabs {
                border: none;
                justify-content: flex-start;

                .nav-item {
                    border: none;
                    background: transparent;

                    .nav-link {
                        padding-left: 0;
                        padding-top: 0;
                        padding-bottom: 20px;
                        border: none;
                        background: transparent;
                        color: $grey3;
                        font-weight: 400;
                        font-size: 0.924rem;
                        line-height: 24px;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;

                        &.active {
                            color: $accent2;
                            font-size: 0.9375rem;
                            font-weight: $fwBold;
                        }
                    }
                }
            }
        }
    }

    &__gallery {

        .article__insideImage:not(.drastic-photo),
        .article__insideImage.drastic-photo.show {
            cursor: zoom-in;
        }

        &__multiple {

            .glide__slide {
                &:not(.glide__slide--active) {
                    .article__insideImageFigcaption {
                        display: none;
                    }
                }

                &.glide__slide--active {
                    .article__insideImageFigcaption {
                        display: flex;
                        margin-top: 16px;
                        color: $black;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: start;
                        flex-wrap: wrap;
                        font-family: $sansPro;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 21px;
                        letter-spacing: 0.011em;
                        >div {
                            flex: 1 0 0%;
                            white-space: normal;
    
                            &.article__insideImageFigcaption__desc {
                                a {
                                    color: $accent2;
                                    text-decoration: none;
                                }
                            }
    
                            &.article__insideImageFigcaption__source {
                                flex: 0 0 auto;
                                width: 100%;
                                margin-top: 4px;
                                color: $grey4;
                                font-family: $sansPro;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 20px;
                                letter-spacing: 0.03em;
    
                                @media(min-width:650px) {
                                    width: auto;
                                    max-width: 50%;
                                    margin-top: 0;
                                    padding-left: 20px;
                                    text-align: right;
                                }
    
                            }
                        }
                        p {
                            margin-bottom: 0;
                            color: inherit;
                            font-family: inherit;
                            font-weight: inherit;
                            font-size: inherit;
                            line-height: inherit;
                            letter-spacing: inherit;
                        }
    
                        @media(min-width:650px) {
                            margin-top: 8px;
                            text-align: left;
                        }
                    }
                }
            }
        }

        &__single {
            background: transparent;
            padding: 0;

            .glide__slide {
                img {
                    width: 100%;
                    border-radius: 4px;
                }

                .article__insideImageFigcaption {
                    display: flex;
                    margin-top: 16px;
                    color: $black;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: start;
                    flex-wrap: wrap;
                    font-family: $sansPro;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 21px;
                    letter-spacing: 0.011em;

                    >div {
                        flex: 1 0 0%;
                        white-space: normal;

                        &.article__insideImageFigcaption__desc {
                            a {
                                color: $accent2;
                                text-decoration: none;
                            }
                        }

                        &.article__insideImageFigcaption__source {
                            flex: 0 0 auto;
                            width: 100%;
                            margin-top: 4px;
                            color: $grey4;
                            font-family: $sansPro;
                            font-weight: 400;
                            font-size: 0.875rem;
                            line-height: 20px;
                            letter-spacing: 0.03em;

                            @media(min-width:650px) {
                                width: auto;
                                max-width: 50%;
                                margin-top: 0;
                                padding-left: 20px;
                                text-align: right;
                            }

                        }
                    }

                    p {
                        margin-bottom: 0;
                        color: inherit;
                        font-family: inherit;
                        font-weight: inherit;
                        font-size: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                    }

                    @media(min-width:650px) {
                        margin-top: 8px;
                        text-align: left;
                    }
                }
            }
        }

    }

    &__relatedArticle {
        display: block;
        margin-bottom: 24px;
        text-decoration: none;

        .box__image {
            z-index: 0;
            display: block;
            width: 100%;
            height: 243px;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @media(min-width:650px) {
                height: 418px;
            }

            @media(min-width:992px) {
                height: 552px;
            }
        }

        .box__text {
            &Title {
                margin-top: 16px;
                color: $black;
                font-family: $sansPro;
                font-weight: 400;
                font-size: 1rem;
                line-height: 21px;
                letter-spacing: 0.011em;

                @media(min-width:650px) {
                    margin-top: 0;
                    text-align: left;
                }
            }

            &SubTitle {
                margin-top: 4px;
                color: $grey4;
                font-family: $sansPro;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 20px;
                letter-spacing: 0.03em;

                @media(min-width:650px) {
                    min-width: 250px;
                    margin-top: 0;
                    text-align: right;
                }

                @media(min-width:992px) {
                    min-width: 375px;
                }
            }

            @media(min-width:650px) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
            }
        }
    }

    &.article-base {
        .article__top {
            min-height: unset;
            margin-bottom: 24px;

            .article__header {
                margin-bottom: 0;
            }
        }

        .article__author {
            display: flex;
            margin-bottom: 70px;

            &Image {
                position: relative;
                display: inline-block;
                overflow: hidden;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: $surface5;

                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: auto;
                    max-width: unset;
                    height: 100%;
                    transform: translateX(-50%);
                }
            }

            &Content {
                margin-left: 12px;

                .name {
                    display: block;
                    color: $surface1;
                    font-weight: $fwMediumBold;
                    font-size: 1.375rem;
                    line-height: 26px;
                    letter-spacing: 0.02em;
                    text-decoration: none;
                }

                .mail-icon {
                    display: inline-block;
                    width: 23px;
                    height: 23px;
                    background: url('/assets/images/mail_icon.svg') no-repeat center center transparent;
                }
            }

            @media(min-width:650px) {
                margin-bottom: 100px;
            }
        }

        .article__mainContent {
            h2 {
                font-weight: $fwBold;
            }

            .article__gallery__single {
                max-width: 825px;
            }

            figure {
                max-width: 100%;

                img {
                    height: auto !important;
                }
            }
        }
    }

    &.article-opinion {
        .article__mainContent {
            h2 {
                font-weight: $fwBold;
            }

            .article__gallery__single {
                max-width: 825px;
            }

            figure {
                max-width: 100%;

                img {
                    height: auto !important;
                }
            }
        }

        .article__author {
            a.article__author__name {
                color: $accent2;
            }
        }
    }

    &__socialmedia {
        display: flex;
        margin-bottom: 32px;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            display: inline-block;

            +a {
                margin-left: 16px;
            }
        }

        &__icon {
            display: block;
            overflow: hidden;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #fff;
            background-position: center center;
            background-size: cover;

            &.facebook {
                background-image: url('/assets/images/facebook_icon_circle.svg');
            }

            &.youtube {
                background-image: url('/assets/images/youtube_icon_circle.svg');
            }

            &.telegram {
                background-image: url('/assets/images/telegram_icon_circle.svg');
            }

            &.twitter {
                background-image: url('/assets/images/twitter_icon_circle.svg');
            }
        }
    }

    &__tags {
        display: flex;
        margin-bottom: 27px;
        margin-left: -10px;
        margin-right: -10px;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            display: inline-block;
            margin: 5px 10px;
            padding: 4px 10px;
            border-radius: 16px;
            background: $surface2;
            color: $grey3;
            font-weight: 400;
            font-size: 1rem;
            line-height: 21px;
            letter-spacing: 0.011em;
            text-decoration: none;
        }
    }

    blockquote {
        position: relative;
        margin: 0 auto $sectionMargin;
        padding-left: 70px;
        padding-right: 20px;
        color: $accent2;
        font-family: $serifPro;
        font-style: italic;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 40px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 46px;
            height: 46px;
            background: url('/assets/images/quote_orange.svg') no-repeat center center transparent;
            content: '';
        }
    }

    .reference {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 36px 23px 17px;
        border-radius: 4px;
        background: $surface2;
        color: $grey4;
        font-family: $sansPro;
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;

        .reference-label {
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px 7px;
            border-radius: 4px 0 0 0;
            background: $accent2;
            color: #FFFFFF;
            font-family: $sansPro;
            font-weight: $fwBold;
            font-size: 0.875rem;
            line-height: 14px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
    }

    &__banner {
        position: relative;
        height: 200px;
        background: $surface2;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            color: #000;
            font-family: $sansPro;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 15px;
            transform: translate(-50%, -50%);
        }
    }
}

.long-page-container {
    @media(min-width:992px) {
        max-width: 1024px;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
    }

}

.article-text {
    max-width: 1024px;

    h2,
    h3,
    h4 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: #111111;
        font-family: $sansPro;
        font-weight: $fwBold;
    }

    p {
        &:first-child {
            color: $grey1;
            font-size: 1.5rem;
            font-weight: $fwMediumBold;
            font-family: $serifPro;
            line-height: 32px;
        }

        @media(min-width:992px) {
            &:not(:first-child):not(.reference) {
                max-width: 880px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

}

.article-longread {
    >* {
        &:first-child {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #ccc;
            font-family: $serifPro;
            font-style: normal;
            font-weight: $fwMediumBold;
            font-size: 24px;
            line-height: 32px;
        }

        a {
            color: $accent2;
            text-decoration: none;
        }
    }

    .longread-header {
        font-family: $sansPro;
        font-style: normal;
        font-weight: $fwBold;
        font-size: 22px;
        line-height: 32px;
        color: #111111;
    }

    >h2,
    >h3,
    >h4 {
        max-width: 880px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.lightboxOverlay {
    background-color: #fff;
    opacity: 1;
}

.lightbox {
    .lb-dataContainer {
        position: absolute;
        bottom: 100%;
        right: 0;

        @media(min-width:992px) {
            right: 40px;
        }
    }

    .lb-outerContainer {
        top: calc(50vh - 50px);
        transform: translateY(-50%);

        @media(min-width:992px) {
            max-width: 73%;
        }
    }

    .lb-image {
        @media(min-width:992px) {
            max-width: 70vw;
            height: auto !important;
        }
    }

    .lb-data {
        .lb-close {
            width: 68px;
            height: 35px;
            background-position: center center;
            opacity: 1;
        }
    }

    .lb-nav {

        a.lb-prev:hover,
        a.lb-next:hover {
            opacity: 0;
        }

        @media(min-width:992px) {
            top: 50%;
            left: 50%;
            width: 100vw;
            height: auto;
            padding-left: 60px;
            padding-right: 60px;
            transform: translate(-50%, -50%);

            a.lb-prev,
            a.lb-next {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: rgba(196, 196, 196, 0.6);
                background-position: center center;
                opacity: 1;
            }
        }
    }
}

.article-left-content {
    @media (min-width: 992px) {
        max-width: 65.6%;
    }

    @media (min-width: 1104px) {
        max-width: 69.162%;
    }
}

.article__generic.twitter-tweet {
    margin: 0 auto;
    text-align: center;

}

.modal-lightbox {
    position: fixed;
    z-index: 99999;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transition: all 0.3s;

    &.open {
        display: block;
        opacity: 1;
    }

    .lightbox-actions {
        position: absolute;
        z-index: 2;
        right: 35px;

        >span {
            position: relative;
            display: inline-block;
            padding: 9px 9px 0;
            background: rgba(255, 255, 255, .7);
            font-size: 18px;
            cursor: pointer;

            &.modal-lightbox-copy {
                &.copied {
                    color: darkgreen;

                    &::after {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        padding: 3px 5px;
                        border-radius: 4px;
                        background: darkgreen;
                        color: #fff;
                        font-size: 11px;
                        transform: translateX(-50%);
                        content: attr(message);
                    }
                }
            }
        }
    }

    .modal-lightbox-close {
        position: absolute;
        display: block;
        right: 10px;
        top: 0;
        width: 40px;
        height: 40px;
        z-index: 5;

        span {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgba($color: #ffffff, $alpha: 0.5);
            color: $surface1;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        // height: 80vh;
        padding: 10px;
        border-radius: 4px;
        background: #fff;
        transform: translate(-50%, -50%);

        .lightbox-slide {
            position: relative;
            width: fit-content;
            margin: 0 auto;
            text-align: center;

            img {
                max-width: 100%;
                max-height: 100vh;
                border-radius: 4px;
            }
        }

        // @media (min-width:650px) {
        //     width: 80vw;
        // }
    }

    .lightbox-control {
        position: absolute;
        z-index: 1;
        top: 50%;
        padding: 16px;
        border-radius: 50%;
        background: #C4C4C4;
        transform: translateY(-50%);
        cursor: pointer;

        &::after {
            display: block;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            content: '';
        }

        &.slide-prev {
            left: 5px;

            &::after {
                border-bottom: 2px solid #000;
                border-left: 2px solid #000;
            }

            @media (min-width:650px) {
                left: 60px;
            }
        }

        &.slide-next {
            right: 5px;

            &::after {
                border-top: 2px solid #000;
                border-right: 2px solid #000;
            }

            @media (min-width:650px) {
                right: 60px;
            }
        }
    }
}

.addnotation {
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 30px;
}