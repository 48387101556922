footer {
    position: relative;
    margin-top: 31px;
    padding-top: 32px;
    padding-bottom: 31px;
    background: $surface1;
    color: #fff;

    .footer-col {
        position: relative;
        margin-top: 50px;
        text-align: left;

        .footer-col-content {
            display: inline-block;
            text-align: left;

            .footer-links {
                padding: 0;
                margin: 0;
                list-style: none;

                >li {
                    max-width: 150px;
                    margin-bottom: 10px;

                    a {
                        display: inline-block;
                        color: #fff;
                        line-height: 24px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .footer-head {
                margin-bottom: 40px;
                font-size: 1.125rem;
                font-weight: $fwBold;
            }
        }

        &.footer-group-1 {
            text-align: left;

            @media(min-width:576px) {
                text-align: left;
            }

            @media(min-width:992px) {
                &:after {
                    right: 40px;
                }
            }

            @media(min-width:1280px) {
                text-align: center;

                &:after {
                    right: 0;
                }
            }
        }

        &.footer-group-3:after {
            @media(min-width:576px) {
                display: none;
            }

            @media(min-width:992px) {
                display: block;
            }
        }

        &.footer-group-4 {
            text-align: left;

            @media(min-width:576px) {
                text-align: left;
            }

            @media(min-width:992px) {
                text-align: center;
            }
        }

        &.footer-group-5 {
            .footer-links {
                >li {
                    a {
                        white-space: nowrap;

                        @media(max-width:311px) {
                            white-space: normal;
                        }
                    }
                }
            }
        }

        &.footer-group-6 {
            text-align: left;
        }

        @media(min-width:576px) {
            text-align: center;

            &:not(.footer-group-6) {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.11);
                    content: '';
                }
            }
        }
        @media(min-width:992px) AND (max-width:1279px) {
            &.footer-group-1 {
               width: 20%;
            }
            &.footer-group-2 {
               width: 20%;
            }
            &.footer-group-3 {
               width: 20%;
            }
            &.footer-group-4 {
               width: 40%;
               &:after {
                   display: none;
               }
            }
            &.footer-group-5 {
               width: 20%;
               text-align: left;
            }
            &.footer-group-6 {
                text-align: center;
               width: 18%;
            }
        }
        @media(min-width:1280px) {
            &:not(.footer-group-6) {
                &:after {
                    right: -30px;
                }
            }
        }
    }

    .copyright {
        margin-top: 51px;
        color: #EAF9FF;
        font-size: 0.8125rem;
        letter-spacing: 0.05em;
    }

    @media(min-width:992px) {
        padding-top: 62px;
        padding-bottom: 55px;
    }
    @media(min-width:992px) AND (max-width:1279px) {
.justify-footer{
    justify-content: flex-start!important;
}
    }
    @media(max-width:575px) {
        >.container {
            max-width: 100%;
        }
    }

    @media(max-width:311px) {
        position: relative;
        margin-top: 30px;
    }
}