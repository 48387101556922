*{  // Beacuse some parts of texts - e.g. illustration's captions are unable to select, 
    // we use this CSS declaration explicitly.    
    user-select: text; 
}

body {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: $surface3;
    color: $black;
    font-family: $sansPro;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
}

ion-router-outlet {
    overflow: scroll;
    overflow-x: hidden;
    
}

app-header {
    position: sticky;
    top: 0;
    z-index: 999;
}
app-scroller{
    position: sticky;
    top: 23%;
    z-index: 1000;
}

    
.ion-page {
    // overflow: auto;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

body.side-bar-open ion-router-outlet {
    overflow: hidden;
}

section.container,
section.container-fluid,
.section-margin {
    margin-bottom: $sectionMarginMobile;

    &.boxes {
        margin-bottom: 0;
    }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    padding-left: 8px;
    padding-right: 8px;
}

button {
    cursor: pointer;
}

img {
    max-width: 100%;
}

.glide__slide--active {
    position: relative;
}

img.drastic-photo:not(.show),
.drastic-video:not(.show) 
{
    filter: blur(20px);
}

.drastic-photo-item {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}
.drastic-video-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.drastic-photo-item:not(.show)::after,
.drastic-video-item:not(.show)::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .4);
    content: '';
    pointer-events: none;
}
.drastic-video-item:not(.show)::after{
    pointer-events: auto;
}

.drastic-video-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 28%;
    color: #fff;
    text-align: center;
    pointer-events: none;

    >span {
        display: block;

        &.drastic-video-icon {
            width: 41px;
            height: 38px;
            margin: 0 auto 10px;
            background: url('/assets/images/un-visible.svg') no-repeat center center transparent;
        }
    }
}
.drastic-photo-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 28%;
    color: #fff;
    text-align: center;
    pointer-events: none;

    >span {
        display: block;

        &.drastic-photo-icon {
            width: 41px;
            height: 38px;
            margin: 0 auto 10px;
            background: url('/assets/images/un-visible.svg') no-repeat center center transparent;
        }
    }
}

.glide__slide--clone .drastic-photo-info,
.drastic-photo-item.show+.drastic-photo-info,
.drastic-video-item.show+.drastic-video-info {
    display: none;
}

h1 {
    color: $surface1;
    font-size: 2.5rem;
    font-weight: $fwBold;
    line-height: 48px;
}

h2 {
    color: $grey2;
    font-family: $serifPro;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 28px;
}

hr {
    margin: $sectionMarginMobile 0;
    border-top: 1px solid $grey5;
}

hr.row-separator {
    margin: 0 0 $sectionMarginMobile;
}

.vertical-hr {
    width: 1px;
    height: 100%;
    background: $grey5;
}

.movie-iframe {
    position: relative;
    padding-top: 52%;
}

.movie-iframe iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
}

iframe {
    max-width: 100%;
}

.mt-6 {
    margin-top: 3.5rem !important;
}

.color-black {
    color: $black;
}

.color-grey-1 {
    color: $grey1;
}

.color-grey-2 {
    color: $grey2;
}

.color-grey-3 {
    color: $grey3;
}

.color-grey-4 {
    color: $grey4;
}

.color-grey-5 {
    color: $grey5;
}

.color-surface-1 {
    color: $surface1;
}

.color-accent-1 {
    color: $accent1;
}

.color-accent-2 {
    color: $accent2;
}

.lh-40 {
    line-height: 40px;
}

p,
.text-page ul li {
    font-size: 1.125rem;
    font-weight: 400;
    font-family: $serifPro;
    line-height: 30px;
}

p,
.text-page ul {
    margin-bottom: 24px;
}

p b {
    font-family: $sansPro;
    font-weight: $fwBold;
}

p .highlight,
p a {
    color: $accent2;
    font-weight: $fwBold;
    text-decoration: none;
}

.text-big,
.about-content>p:first-child {
    font-size: 1.5rem;
    font-weight: $fwMediumBold;
    font-family: $serifPro;
    line-height: 32px;
}

.about-content>p:first-child {
    color: $grey1;
}

.text-mid,
p strong {
    font-family: $sansPro;
    font-size: 1.375rem;
    font-weight: $fwBold;
    line-height: 32px;
}

.text-header {
    font-family: $sansPro;
    margin-bottom: 24px;
    font-size: 1.375rem;
    font-weight: $fwBold;
    line-height: 32px;
}

.text-mid-2 {
    font-size: 1.5rem;
    font-weight: $fwMediumBold;
}

.font-size-medium {
    font-size: 1.5rem;
}

.dropdown ul.dropdown-menu {
    z-index: 1003;
    margin: 0;
    padding: 0;
    background: #fcfcfc;
    list-style: none;
}

.dropdown ul.dropdown-menu>li {
    display: block;
}

.dropdown ul.dropdown-menu>li>a {
    display: block;
    padding: 5px;
}

ul.cols-2 {
    columns: 2;
    column-gap: 60px;
}

.btn-block {
    width: 100%;
}

.btn-big {
    font-size: 2rem;
    font-weight: $fwBold;
}

.btn {
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

.btn.btn-primary {

    min-width: 136px;
    padding: 7px 28px;
    border-color: $accent2;
    border-radius: 5px;
    background-color: $accent2;
    color: #fff;
    font-size: 0.9357rem;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.01em;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active {
    border-color: $accent1;
    background-color: $accent1;
    color: #fff;
}

.btn.btn-danger {
    border-color: $accent1;
    background-color: $accent1;
    border-radius: 5px;
    color: #fff;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active {
    border-color: $accent2 !important;
    background-color: $accent2 !important;
    color: #fff;
}

.btn.btn-outline-primary {
    min-width: 136px;
    padding: 7px 28px;
    border-color: $accent2;
    border-radius: 5px;
    color: $accent2;
    font-size: 0.9357rem;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.01em;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:active {
    border-color: $accent2 !important;
    background-color: $accent2 !important;
    color: #fff;
}

.img-xs {
    display: block;
}

.img-lg,
.img-md,
.img-sm {
    display: none;
}

.row-btn-more {
    margin-top: 0;
    margin-bottom: $sectionMarginMobile;

    @media(min-width:650px) {
        /*margin-top: $sectionMargin;*/
        margin-bottom: $sectionMargin;
    }
}

.not-found {
    margin-bottom: 15em;

    &-image {
        margin-top: 20px;

        img {
            max-height: 225px;

            @media(min-width:650px) {
                max-height: unset;
            }
        }
    }

    &-message {
        margin-top: 12px;

        .title {
            margin-bottom: 16px;
            color: $surface1;
            font-weight: $fwBold;
            font-size: 1.125rem;
            line-height: 18px;
            text-align: center;

            @media(min-width:650px) {
                margin-bottom: 8px;
                font-size: 1.375rem;
                line-height: 32px;
            }
        }

        .subtitle {
            color: $grey3;
            font-family: $firaSans;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 20px;
            text-align: center;

            a {
                color: $accent2;
            }

            @media(min-width:650px) {
                font-size: 1rem;
                line-height: 25px;
            }
        }

        @media(min-width:650px) {
            margin-top: 0;
        }
    }
}

.blank-page {
    text-align: center;
}

.alert {
    display: flex;
    margin-top: $subHeaderMarginTop;
    margin-bottom: $headerMarginBottom;
    padding: 4px;
    font-family: $sansPro;
    font-style: normal;
    font-weight: $fwMediumBold;
    font-size: 0.875rem;
    line-height: 18px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    &-warning {
        background: $accent2;
        color: #fff;
    }
    >span{
        &.message{
            padding-top: 2px;
        }
        &.alert-icon {
        margin-right: 9px;
    }
}
    @media(min-width: 576px){
        justify-content: center;
        align-items: center;
    }
}

@media(min-width:376px) {

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media(min-width:576px) {
    // body {
    //     padding-bottom: 880px;
    // }

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media(min-width:650px) {

    section.container,
    section.container-fluid,
    .section-margin {
        margin-bottom: $sectionMargin;
    }

    hr {
        margin: $sectionMarginMobile 0;
    }

    hr.row-separator {
        margin: 0 0 $sectionMarginMobile;
    }

    .img-xs {
        display: none;
    }

    .img-sm {
        display: block;
    }
}

@media(min-width:992px) {
    // body {
    //     padding-bottom: 613px;
    // }
    

    .col-text {
        max-width: 750px;
    }

    .img-xs,
    .img-sm {
        display: none;
    }

    .img-md {
        display: block;
    }

    .col-right-bar {
        max-width: 333px;
    }
}

@media(min-width:1240px) {
    .img-xs,
    .img-sm,
    .img-md {
        display: none;
    }

    .img-lg {
        display: block;
    }

    .col-right-bar {
        max-width: 375px;
    }
}

@media(min-width:1280px) {
    // body {
    //     padding-bottom: 538px;
    // }

    .xl-bottom-space {
        margin-bottom: 170px;
    }
}

@media(max-width:991px) {
    .md-mt-30 {
        margin-top: 30px;
    }
}

@media(max-width:575px) {
    .top-navbar-container>.container-fluid {
        max-width: 100%;
    }
}

@media(max-width:311px) {
    // body {
    //     padding-bottom: 0;
    // }
}