.boxes {
    &--orangeLight {
        .box-content {
            background: #F2E7E0;
            margin-bottom: 24px;

            >.row {
                margin: 0;

                .box-col {
                    margin-bottom: 16px;
                    padding: 16px 0 16px 16px;
                    border-bottom: 1px solid $grey5;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media(min-width:576px) {
                        margin-bottom: 0;

                        &:nth-child(odd) {
                            border-right: 1px solid #BCBCBC;
                        }
                    }

                    @media(min-width:992px) {
                        border-right: 1px solid #BCBCBC;

                        &:nth-child(4n+0) {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
}

.box-row-100 {
    height: 100%;
}

.col-box-item {
    position: relative;
    margin-bottom: 16px;


    @media(min-width:650px) {
        margin-bottom: 15px;
    }

    &.line-bottom {
        margin-bottom: 48px;

        &:after {
            position: absolute;
            bottom: -24px;
            left: 8px;
            right: 8px;
            height: 1px;
            background: rgba(0, 0, 0, .11);
            content: '';
        }
    }

}




.col-box-item-big {
    position: relative;
    padding-bottom: 33px;

    &:after {
        position: absolute;
        bottom: 16px;
        left: 8px;
        right: 8px;
        height: 1px;
        background: rgba(0, 0, 0, .11);
        content: '';

        @media(min-width:992px) {
            right: -8px;
            bottom: 25px;
        }
    }
}

.big-box-item {
    width: auto;

}

.mobile-col-box-item-big {
    @media(max-width:991px) {
        position: relative;
        padding-bottom: 33px;

        &:after {
            position: absolute;
            bottom: 16px;
            left: 8px;
            right: 8px;
            height: 1px;
            background: rgba(0, 0, 0, .11);
            content: '';

            @media(min-width:992px) {
                right: -8px;
                bottom: 25px;
            }
        }
    }
}

.row-box-xs-2-cols {
    .col-box-item {
        margin-bottom: 33px;

        &:after {
            position: absolute;
            bottom: -16px;
            left: 8px;
            right: -8px;
            height: 1px;
            background: rgba(0, 0, 0, .11);
            content: '';
        }

        &:nth-child(2n+0):after {
            right: 8px;
        }

        &:nth-last-child(-n+2) {
            margin-bottom: 33px;
        }

        /*        @media(max-width:359px){
                    margin-bottom: 19px;
                    &:after{
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        right: auto;
                        width: 100%;
                        max-width: 220px;
                        transform: translateX(-50%);
                    }
                }*/
    }


    &.last-row-no-border {
        .col-box-item:nth-last-child(-n+2) {

            /*margin-bottom: 0;*/
            &:after {
                display: none;
            }
        }
    }

    @media(min-width:992px) {

        &.row-box-lg-3-cols,
        &.row-box-lg-4-cols {
            .col-box-item {
                &:nth-child(2n+0):after {
                    right: -8px;
                }
            }
        }
    }
}

@media(min-width:992px) {
    .row-box-xs-2-cols {
        .col-box-item {
            margin-bottom: 49px;

            &:nth-last-child(-n+2) {
                margin-bottom: 49px;
            }

            &:after {
                bottom: -24px;
            }
        }
    }

    .row-box-lg-3-cols,
    .row-box-lg-4-cols {
        .col-box-item {
            margin-bottom: 49px;

            &:after {
                position: absolute;
                bottom: -24px;
                left: 8px;
                right: -8px;
                height: 1px;
                background: rgba(0, 0, 0, .11);
                content: '';
            }
        }
    }

    .row-box-lg-3-cols {
        .col-box-item {
            &:nth-child(2n+0):after {
                right: 8px !important;
            }

            &:nth-last-child(-n+3) {
                margin-bottom: 24px;
            }
        }

        &.last-row-no-border {
            .col-box-item:nth-last-child(-n+3) {

                /*margin-bottom: 0;*/
                &:after {
                    display: none;
                }
            }
        }
    }

    .row-box-lg-4-cols {
        .col-box-item {
            &:nth-child(4n+0):after {
                right: 8px !important;
            }

            /*            &:nth-last-child(-n+4){
                            margin-bottom: 24px;
                        }*/
        }

        &.last-row-no-border {
            .col-box-item:nth-last-child(-n+4) {

                /*margin-bottom: 0;*/
                &:after {
                    display: none;
                }
            }
        }
    }
}

.box {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    min-height: 288px;
    min-width: 134px;
    padding-bottom: 0;
    border-radius: 4px;
    text-decoration: none;

    &.full-background {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .box__text--background {
            top: unset;
            transform: unset;
        }
    }

    &__image {
        z-index: 0;
        display: block;
        width: 100%;
        height: 280px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &__text {
        position: relative;
        z-index: 1;
        margin-top: 8px;

        &Label {
            margin-bottom: 4px;
            color: $accent2;
            font-size: 0.875rem;
            line-height: 14px;
            font-weight: $fwBold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        &Title {
            color: $surface1;
            font-weight: $fwBold;
            font-size: 1.75rem;
            line-height: 22px;
            letter-spacing: -0.01em;
        }

        &Description {
            margin-top: 8px;
            color: $grey3;
            font-family: $serifPro;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 20px;
            letter-spacing: 0.005em;

            * {
                margin: 0;
                font-size: inherit;
                font-weight: inherit;
                font-family: inherit;
                line-height: inherit;
            }
        }

        &Date {
            position: relative;
            z-index: 1;
            margin-top: 24px;
            color: $grey4;
            font-size: 0.8125rem;
            line-height: 12px;
            letter-spacing: 0.04em;
        }

        &--background {
            // position: absolute;
            position: relative;
            top: 100%;
            bottom: 16px;
            margin-top: 16px;
            padding: 16px 16px 0;
            transform: translateY(calc(-100% - 28px));

            .box__textTitle {
                color: #fff;
            }

            .box__textDescription {
                color: #fff;
            }
        }
    }

    &__image--absolute {
        position: absolute;
        height: 100% !important;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
            content: '';
        }
    }

    &.box--small {
        .box__image {
            height: 120px;

            @media(min-width:576px) {
                height: 150px;
            }
        }

        .box__text {
            .box__textTitle {
                font-size: 1.125rem;
            }

            .box__textDescription {
                font-size: 1rem;
            }
        }

        .box__textDate {
            margin-top: 16px;
        }
    }

    &.box--big {
        max-width: 100%;

        .box-big-content {
            position: relative;
            height: calc(100% - 16px);
            min-height: 280px;

            &::before {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.4);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
                border-radius: 4px;
                content: '';
            }

            .box__text {
                padding-top: 16px;
            }

            .box__image {
                position: absolute;
                z-index: 0;
                height: 100%;
                min-height: 280px;
                border-radius: 4px;
                background-position: top center;
            }

            .box-big-caption {
                position: relative;
                z-index: 2;
                top: 100%;
                padding: 8px;
                transform: translateY(-100%);

                .box__text {
                    .box__textTitle {
                        color: #fff;
                        line-height: 32px;
                    }

                    .box__textDescription {
                        color: #fff;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    &.box-quote {
        padding-top: 8px;
        background: $accent2;

        .box__text {
            padding: 48px 16px 16px;

            &:before {
                position: absolute;
                top: 0;
                left: 16px;
                width: 26px;
                height: 26px;
                background: url('/assets/images/quote.svg') no-repeat center center transparent;
                content: '';
            }

            .box__textTitle {
                color: #fff;
                font-size: 1rem;
                font-weight: $fwMediumBold;
                line-height: 16px;
                text-transform: uppercase;
                letter-spacing: 0.04em;

                @media(min-width:576px) {
                    font-size: 1.25rem;
                    line-height: 22px;
                }
            }

            .box__textDescription {
                color: #fff;
                font-family: $serifPro;
                font-size: .875rem;
                letter-spacing: 0.005em;

                @media(min-width:576px) {
                    font-size: 1rem;
                }
            }

        }
    }

    &.box-text {
        .box__text {
            .box__textTitle {
                color: $surface1;
                font-size: 1.125rem;
                font-weight: $fwBold;
                line-height: 22px;
                letter-spacing: -0.01em;

                @media(min-width:576px) {
                    font-size: 1.25rem;
                    line-height: 24px;
                }
            }

            .box__textDescription {
                color: $grey3;
                font-family: $serifPro;
                font-size: 1rem;
                line-height: 20px;
                letter-spacing: 0.005em;
            }
        }
    }

    @media(min-width:456px) {
        min-width: 164px;
    }

    @media(min-width:556px) {
        min-width: 171px;
    }
}

.authorBox {
    display: flex;
    height: 100%;
    ;
    min-height: 200px;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;

    &__content {
        margin-bottom: 20px;

        .authorBox__title {
            color: $surface1;
            font-size: 1.125rem;
            font-weight: $fwBold;
            line-height: 22px;
            letter-spacing: -0.01em;
        }

        .authorBox__subtitle {
            color: $grey3;
            font-family: $serifPro;
            font-size: 1rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.005em;

            p {
                color: inherit;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                letter-spacing: inherit;
            }
        }

        .authorBox__date {
            margin-top: 10px;
            color: $grey4;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0.04em;
        }
    }

    &__textAuthor {
        position: relative;

        &__name {
            color: $accent2;
            font-size: 0.875rem;
            font-weight: $fwBold;
            line-height: 16px;
            letter-spacing: 0.03em;
        }

        &__description {
            position: relative;
            z-index: 1;
            padding-bottom: 16px;
            color: $grey4;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.03em;
            /*white-space: nowrap;*/
        }

        &Image {
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: 12px;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.transmissions-box {
    border-radius: 4px;

    .transmissions-header {
        padding: 12px 16px 24px;
        background: $accent1;
        border-radius: 4px 4px 0 0;
        color: #fff;
        font-size: 1.25rem;
        font-weight: $fwMediumBold;
        line-height: 20px;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        h1 {
            display: inline-block;
            margin: 0;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            text-transform: inherit;
        }

        .stream-switch {
            float: right;
            border: none;

            li {
                .nav-link {
                    padding: 0;
                    border: none;
                    background: transparent;
                    color: #fff;
                    font-size: 1rem;
                    line-height: 20px;
                    letter-spacing: 0.011em;
                    text-decoration: none;
                    text-transform: uppercase;
                    opacity: 0.44;

                    @media(min-width:650px) {
                        line-height: 26px;
                    }

                    &.active {
                        opacity: 1;
                    }
                }

                +li {
                    margin-left: 5px;

                    @media(min-width:650px) {
                        margin-left: 30px;
                    }
                }
            }

            a {
                display: inline-block;
                color: #fff;
                font-size: 1rem;
                line-height: 20px;
                letter-spacing: 0.011em;
                text-decoration: none;
                opacity: 0.44;

                @media(min-width:650px) {
                    line-height: 26px;
                }

                &.active {
                    opacity: 1;
                }

                +a {
                    margin-left: 5px;

                    @media(min-width:650px) {
                        margin-left: 30px;
                    }
                }
            }
        }
    }

    .transmissions-video {
        background: $grey2;
        padding: 16px;

        .tab-pane {
            position: relative;
            padding-top: 56%;
            width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .transmission-loader {
            position: relative;
            display: block;
            width: 100%;
            height: 160px;
            color: #fff;
            font-weight: $fwBold;
            font-size: 0.8125rem;
            line-height: 20px;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.05em;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60px;
                height: 60px;
                border: 2px solid $accent2;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: url('/assets/images/play.svg') no-repeat center center transparent;
                content: '';
            }

            @media(min-width:992px) {
                height: 186px;
            }
        }
    }

    .transmissions-list {
        padding: 16px;
        border-radius: 0 0 4px 4px;
        background: $grey1;

        >ul {
            padding: 0;
            margin: 0 0 30px;
            list-style: none;

            >li {
                margin-bottom: 5px;
                letter-spacing: 0.011em;

                >span {
                    font-size: 1rem;
                    line-height: 20px;
                }

                >.hour {
                    color: $grey4;
                    font-weight: 400;
                }

                >.title {
                    display: inline-block;
                    max-width: calc(100% - 45px);
                    margin-left: 5px;
                    color: #fff;
                    font-family: $firaSans;
                    font-weight: $fwBold;
                    vertical-align: middle;

                    &.now-item {
                        max-width: calc(100% - 112px);
                    }
                }

                >.now-label {
                    display: inline-block;
                    margin-left: 15px;
                    padding: 0 6px;
                    border-radius: 3px;
                    background: $accent1;
                    color: #fff;
                    font-family: $firaSans;
                    font-size: 0.8125rem;
                    font-weight: $fwMediumBold;
                    line-height: 20px;
                    vertical-align: middle;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                }
            }
        }

        >a {
            position: relative;
            padding-left: 24px;
            color: $accent1;
            font-size: 1.0625rem;
            line-height: 26px;
            text-decoration: none;
            letter-spacing: 0.011em;

            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                width: 16px;
                height: 14px;
                transform: translateY(-50%);
                background: url('/assets/images/arrow_red.svg') no-repeat center center transparent;
                content: '';
            }
        }
    }

    &.transmissions-box-large {
        margin-top: 0;

        @media(min-width:650px) {
            .transmissions-video {
                .transmission-loader {
                    height: 392px;

                    &:before {
                        width: 70px;
                        height: 70px;
                        background-size: 100%;
                    }

                    @media(min-width:992px) {
                        height: 658px;
                    }
                }
            }

            .transmissions-list {
                display: flex;
                overflow: hidden;
                flex-direction: row;
                flex-wrap: nowrap;

                >ul {
                    display: flex;
                    overflow: hidden;
                    max-width: calc(100% - 200px);
                    margin: 0;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    li {
                        margin-right: 20px;
                        white-space: nowrap;

                        .title {
                            margin-left: 5px;
                        }
                    }
                }

                >a {
                    white-space: nowrap;
                    margin-left: 20px;
                }
            }
        }
    }

    @media(min-width:576px) {
        margin-top: $sectionMargin;
    }

    @media(min-width:992px) {
        margin-top: $sectionMargin;
    }
}

.col-right-bar-article {
    .transmissions-box {
        min-width: 335px;
        margin-top: 0;
    }
}

.program-box-big {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    text-decoration: none;
    height: 440px;
    width: 540px;

    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        content: '';
    }

    >img {
        position: relative;
        left: 50%;
        top: 50%;
        height: 100%;
        min-width: 100%;
        max-width: unset;
        min-height: 100%;
        max-height: unset;
        border-radius: 5px;
        transform: translate(-50%, -50%);

        @media(min-width:576px) {
            &.img-xs {
                display: none;
            }

            &.img-sm {
                display: block;
            }
        }

        @media(min-width:992px) {
            &.img-sm {
                display: none;
            }
        }
    }

    >.program-box-big-caption {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;
        color: #fff;

        &:before {
            position: absolute;
            bottom: 100%;
            left: 50%;
            width: 60px;
            height: 60px;
            border: 2px solid $accent2;
            border-radius: 50%;
            transform: translateX(-50%);
            background: url('/assets/images/play.svg') no-repeat center center transparent;
            content: '';
        }

        .program-box-big-label {
            margin-bottom: 8px;
            color: $accent2;
            font-size: 0.875rem;
            line-height: 14px;
            font-weight: $fwBold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            text-decoration: none;
        }

        .program-box-big-title {
            margin-bottom: 10px;
            font-size: 1.625rem;
            font-weight: $fwBold;
            line-height: 28px;
        }

        .program-box-big-description {
            font-size: 1rem;
            font-weight: 400;
            line-height: 20px;

            * {
                margin: 0;
                font-size: inherit;
                font-weight: inherit;
                font-family: inherit;
                line-height: inherit;
            }

            ul {
                padding-left: 20px;
                margin: 0;
            }
        }
    }

    @media(min-width:650px) {
        height: 332px;
        width: 540px
    }

    @media(min-width:992px) {
        height: 350px;
        width: 580px;
    }

    @media (max-width: 991px) {
        width: 600px;
    }

    @media (max-width: 650px) {
        width: 101%;
    }



}


.program-box {
    display: block;
    min-width: 134px;
    text-decoration: none;


    &-image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 120px;
        margin-bottom: 8px;
        border-radius: 4px 4px 0 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.2);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
            content: '';
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 48px;
            height: 48px;
            border: 2px solid $accent2;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background: url('/assets/images/play.svg') no-repeat center center transparent;
            content: '';
        }

        @media(min-width:576px) {
            height: 150px;
        }
    }

    &-caption {
        .program-box-title {
            margin-bottom: 4px;
            color: $surface1;
            font-family: $firaSans;
            font-size: 1.125rem;
            font-weight: 800;
            line-height: 20px;
            letter-spacing: -0.01em;
        }

        .program-box-label {
            margin-bottom: 4px;
            color: $accent2;
            font-size: 0.875rem;
            line-height: 14px;
            font-weight: $fwBold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            text-decoration: none;
        }

        .program-box-description {
            color: $grey3;
            font-family: $serifPro;
            font-size: 1rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.005em;

            * {
                margin: 0;
                font-size: inherit;
                font-weight: inherit;
                font-family: inherit;
                line-height: inherit;
            }
        }

        .program-box-date {
            margin-top: 12px;
            color: $grey4;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.04em;
        }
    }

    &.program-box-v2 {
        .program-box-image {
            overflow: hidden;
            height: 208px;
            margin-bottom: 14px;
            border-radius: 4px;

            &:after {
                width: 70px;
                height: 70px;
                background-size: 100%;
            }

            @media(min-width:650px) {
                height: 374px;
            }
        }

        .program-box-caption {
            .program-box-title {
                margin-bottom: 8px;
                font-family: $sansPro;
                font-weight: $fwBold;
                font-size: 1.5rem;
                line-height: 28px;

                @media(min-width:650px) {
                    margin-bottom: 16px;
                    font-size: 2.25rem;
                    line-height: 38px;
                }
            }

            .program-box-description {
                letter-spacing: normal;

                @media(min-width:650px) {
                    font-size: 1.125rem;
                    line-height: 22px;
                }
            }

            .program-box-date {
                @media(min-width:650px) {
                    margin-top: 16px;
                }
            }
        }
    }

    @media(min-width:456px) {
        min-width: 164px;
    }

    @media(min-width:556px) {
        min-width: 171px;
    }
}

.program-box-single {
    .program-box-image {
        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }
}

.home-news-container {
    position: relative;

    &:after {
        position: absolute;
        bottom: 0;
        left: 8px;
        right: 8px;
        height: 1px;
        background: $grey5;
        content: '';

        @media(min-width:992px) {
            bottom: 24px;
        }
    }

    .box--big {
        padding-bottom: 32px;

        &:after {
            position: absolute;
            bottom: 16px;
            left: 0;
            height: 1px;
            width: 100%;
            background: $grey5;
            content: '';

            @media(min-width:992px) {
                bottom: 0;
            }
        }
    }

    @media(min-width:992px) {
        margin-bottom: -24px;
    }
}

.video-items {
    .video-item {
        margin-bottom: $sectionMarginMobile;
        padding-bottom: $sectionMarginMobile;
        border-bottom: 1px solid $grey5;

        .video-link {
            display: block;
            position: relative;

            &:before {
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.2);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
                content: '';
            }

            &:after {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                width: 53px;
                height: 53px;
                border: 2px solid $accent2;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: url('/assets/images/play.svg') no-repeat center center transparent;
                content: '';
            }
        }

        @media(min-width:650px) {
            margin-bottom: $sectionMargin;
            padding-bottom: $sectionMargin;
        }
    }
}

.journalist-col {
    .journalist-prisoner {
        display: block;
        width: 200px;
        margin: 12px auto;
        text-decoration: none;

        .journalist-image {
            position: relative;
            overflow: hidden;
            width: 200px;
            height: 200px;
            margin: 0 auto 16px;
            border-radius: 50%;
            text-align: center;

            >img {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
            }
        }

        .journalist-name {
            padding: 0 10px;
            color: #000000;
            font-weight: $fwMediumBold;
            font-size: 1.25rem;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        .journalist-description {
            margin-top: 4px;
            color: #000000;
            font-family: $serifPro;
            font-weight: 400;
            font-size: 1rem;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.005em;
        }
    }

    @media(min-width:992px) {
        padding-left: 33px;
        padding-right: 33px;
    }
}

// ------------------------------Started Program-box-big skaletone------------------------

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes loading {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.program-box-big-Skelaton {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    text-decoration: none;
    height: 340px !important;
    width: 540px;
    animation: loading 1.3s infinite;


    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(66, 66, 66, 0.4);
        background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
        content: '';
        opacity: 0.2;
    }

    @media(min-width:650px) {
        height: 332px;
        width: 540px
    }

    @media(min-width:992px) {
        height: 350px;
        width: 580px;
    }

    @media (max-width: 991px) {
        width: 600px;
    }

    @media (max-width: 650px) {
        width: 101%;
    }


}

//------------------------------------------ Program-box-skelaton---------------------------

.program-box-skelaton {
    position: relative;
    height: 156px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    animation: loading 1.3s infinite;
}

.program-box-skelaton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 400% 100%;
    animation: gradient-slide 1.3s ease-in-out infinite;
    background: rgba(66, 66, 66, 0.4);
    background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
    opacity: 0.2;
}

// ----------------------------------Objective box-skelaton------------------------------------

.objective-box-skelaton {
    position: relative;
    height: 156px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    animation: loading 1.3s infinite;
    // >p{

    //    
    // }
}

.objective-box-skelaton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 400% 100%;
    animation: gradient-slide 1.3s ease-in-out infinite;
    background: rgba(66, 66, 66, 0.4);
    background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
    opacity: 0.2;
}


.program-box-text-skelaton {
    position: relative;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    animation: loading 1.3s infinite;
}

.program-box-text-skelaton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 400% 100%;
    animation: gradient-slide 1.3s ease-in-out infinite;
    background: rgba(66, 66, 66, 0.4);
    background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
    opacity: 0.2;
}

.main-banner-skelaton {
    position: relative;
    display: block;
    height: 280px;
    overflow: hidden;
    border-radius: 5px;
    animation: loading 1.3s infinite;

    @media(min-width:650px) {
        height: 440px;
    }

    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(66, 66, 66, 0.4);
        background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
        content: '';
        opacity: 0.2;
    }

}

.contents_skelaton {
    position: relative;
    display: block;
    height: 500px;
    overflow: hidden;
    border-radius: 5px;
    animation: loading 1.3s infinite;

    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(66, 66, 66, 0.4);
        background: linear-gradient(0deg, rgba(49, 49, 49, 0.4), rgba(0, 0, 0, 0.4));
        content: '';
        opacity: 0.2;
    }
}


.scroller-container {
    position: absolute;
    top: 0;
    right: 7%;
    z-index: 999;
    float: right;
    display: flex;
    flex-direction: column;

    .icon1 {
        margin-top: 80px;
        // margin-right: 150px;
    }

    .icon2 {
        margin-top: 250px;
        // margin-right: 150px;

    }

    img:hover {
        cursor: pointer;
        filter: brightness(0.9) drop-shadow(3px 3px 3px rgb(206, 206, 206)) !important;
    }

}

@media (max-width: 1555px) {
    .scroller-container {
        display: none;
    }

}

#scroller_down {
    rotate: 180deg;
}

.mobile-app-scroller {
    position: sticky;
    z-index: 9999;
    bottom: 30px;

    .progress-wrap {
        position: absolute;
        bottom: 30px;
        right: 7%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        z-index: 9999;

        .progress-circle {
            .progress-background {
                fill: none;
                stroke: #ccc;
                stroke-width: 3
            }

            .progress {
                fill: none;
                stroke: #F68D2B;
                stroke-width: 10;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            opacity: 0.8;
            transition: all 0.2s ease-in-out;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

    }
}

@media (min-width: 600px) {
    .mobile-app-scroller {
        display: none;
    }

}