@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@300');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
$sans: 'Source Sans 3', sans-serif;
$sansPro: 'Source Sans Pro', sans-serif;
$firaSans: 'Fira Sans', sans-serif;
$firaSansExtra: 'Fira Sans Extra Condensed', sans-serif;
$serifPro: 'Source Serif Pro', serif;
$inter: 'Inter', sans-serif;
$fwBold: 600;
$fwMediumBold: 600;
$fwSemiBold: 500;