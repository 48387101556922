.top-navbar-container {
    background: $surface1;
    margin-bottom: $headerMarginBottom;
    padding-left: 0;
    padding-right: 0;

    >.container-fluid {
        padding-left: 0;
        padding-right: 0;

        nav.navbar-main {
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            background: $surface1;

            .offcanvas-backdrop {
                &.show {
                    &:before {
                        position: absolute;
                        bottom: 100%;
                        width: 100%;
                        height: 56px;
                        background: transparent;
                        content: '';
                    }
                }
            }

            >.container-fluid {
                padding-left: 0;
                padding-right: 8px;
                .navbar-brand-container{
                    .navbar-brand{
                        position: relative;
                        display: inline-block;
                        padding: 0;
                        background: none;
                        margin: 0;
                        vertical-align: middle;

                        > h1{
                            display: inline-block;
                            margin: 0;
                            line-height: 29px;


                        }
                        img{
                            width: 42.53px;
                            height: 40px;
                        }
                        @media (max-width: 768px){
                           img {
                            max-width: 36.20px;
                            max-height: 34px;
                           }
                        }

                        @media(max-width:306px) {
                            display: block;
                            padding: 5px 10px;
                        }

                        &[data-lng] {
                            &:after {
                                position: absolute;
                                z-index: 1;
                                left: 100%;
                                bottom: 0;
                                padding: 1px 2px;
                                background: #CD4232;
                                color: #fff;
                                font-weight: $fwBold;
                                font-size: 0.625rem;
                                line-height: 9px;
                                letter-spacing: 0.04em;
                                text-transform: uppercase;
                                content: attr(data-lng);
                            }
                        }
                    }

                    .navbar-brand-2 {
                        position: relative;
                        display: inline-block;
                        margin-right: 0;
                        margin-left: 9px;
                        padding-right: 120px;
                        padding-top: 56px;
                        background-image: url(/assets/images/prisoners.png);
                        background-repeat: no-repeat;
                        background-position: top center;
                        background-size: cover;
                        vertical-align: middle;
                        filter: brightness(0.65);

                        .logo-text {
                            display: none;
                            white-space: nowrap;

                            @media(min-width:576px) {
                                position: absolute;
                                z-index: 2;
                                top: 14px;
                                left: calc(100% - 20px);
                                display: inline-block;
                                color: #85AAB2;
                                font-size: .875rem;
                                font-weight: $fwBold;
                                line-height: 16px;
                                text-transform: uppercase;
                                text-decoration: none;
                                letter-spacing: 0.04em;
                                filter: brightness(1.35);
                            }

                            @media(min-width:992px) {
                                left: calc(100% - 20px);
                            }

                            @media(min-width:1033px) {
                                left: calc(100% - 20px);
                            }
                        }

                        @media(min-width:360px) {
                            padding-right: 164px;
                        }

                        @media(min-width:576px) {
                            padding-right: 167px;
                        }

                        @media(max-width:306px) {
                            display: none;
                        }
                    }
                }

                .offcanvas {
                    top: 56px;
                    max-width: 320px;
                    background: $surface2;

                    ul.main-menu {
                        width: 100%;
                        margin-bottom: 0;
                        padding: 0;

                        >li {
                            width: fit-content;

                            a.nav-link {
                                color: $black;
                                font-weight: $fwMediumBold;
                                text-decoration: none;
                                text-transform: uppercase;
                                white-space: nowrap;
                                letter-spacing: 0.05em;

                                >img {
                                    max-height: 14px;
                                    margin-bottom: 3px;
                                }

                                &:hover {
                                    text-decoration: none;
                                    cursor: pointer;
                                }

                                &.active {
                                    position: relative;
                                    color: $black;

                                    &:after {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        bottom: 5px;
                                        height: 2px;
                                        background: #E88E3D;
                                        content: '';

                                        @media(min-width:992px) {
                                            left: 10px;
                                            right: 10px;
                                        }

                                        @media(min-width:1012px) {
                                            left: 10px;
                                            right: 10px;
                                        }

                                        @media(min-width:1280px) {
                                            left: 20px;
                                            right: 20px;
                                        }
                                    }

                                    @media(min-width:992px) {
                                        color: #fff;
                                    }
                                }

                                @media(min-width:992px) {
                                    color: #fff;
                                }

                                @media(min-width:1012px) {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }

                                @media(min-width:1280px) {
                                    padding-left: 20px;
                                    padding-right: 20px;
                                }
                            }

                            @media(min-width:992px) {
                                width: auto;
                            }
                        }

                        @media(min-width:992px) {
                            padding: 0;
                            align-items: center;
                        }
                    }

                    ul.sub-menu {
                        width: 100%;
                        padding: 0 0 20px;
                        margin-bottom: 0;

                        li {
                            a {
                                width: fit-content;
                                color: #414141;

                                &.active {
                                    position: relative;

                                    &:after {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        bottom: 5px;
                                        height: 2px;
                                        background: #E88E3D;
                                        content: '';

                                        @media(min-width:992px) {
                                            left: 10px;
                                            right: 10px;
                                        }

                                        @media(min-width:1012px) {
                                            left: 10px;
                                            right: 10px;
                                        }

                                        @media(min-width:1280px) {
                                            left: 20px;
                                            right: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ul.social-menu {
                        display: flex;
                        max-width: 300px;
                        padding: 0;
                        flex-direction: row;
                        justify-content: space-between;

                        >li {
                            display: inline-block;
                        }
                    }

                    @media(min-width:992px) {
                        position: static;
                        z-index: 1;
                        top: unset;
                        right: unset;
                        max-width: calc(100% - 410px);
                        margin: 0 0 0 auto;
                        background: transparent;
                    }


                }

                .nav-buttons {
                    display: flex;
                    align-items: center;

                    .nav-btn {
                        @media(min-width:576px) {
                            margin-left: 16px;
                        }
                    }

                    .dropdown {
                        .drop-btn {
                            position: relative;
                            padding-right: 15px;
                            border: none;
                            background: transparent;
                            color: rgba(255, 255, 255, .45);
                            font-weight: $fwMediumBold;
                            letter-spacing: 0.05rem;
                        }

                        ul.dropdown-menu {
                            width: fit-content;
                            min-width: 42px;
                            border: 1px solid $surface1;
                            border-radius: 5px;
                            background: $surface3;
                            text-align: center;

                            >li {
                                >a {
                                    display: block;
                                    color: $black;
                                    text-decoration: none;

                                    &:hover {
                                        background: $surface1;
                                        color: #fff;
                                    }

                                    &.active {
                                        background: $surface1;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                    }

                    .search-btn {
                        margin-right: 16px;
                        width: 23px;
                        height: 23px;
                        border: none;
                        background: url('/assets/images/search.svg') no-repeat center center transparent;

                    }

                    .navbar-toggler {
                        padding-right: 0;
                        border: none;

                        &:focus {
                            box-shadow: none;
                        }

                        &.is-open {
                            .navbar-toggler-icon {
                                position: relative;
                                z-index: 20000;
                                width: 1em;
                                height: 1em;
                                background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
                                color: #000;
                                border: 0;
                                box-sizing: content-box;
                                padding: 0.25em 0.25em;
                                opacity: 1;
                                filter: invert(1);
                            }
                        }
                    }
                }

                @media(min-width:307px) {
                    padding-left: 8px;
                }

                @media(min-width:376px) {
                    padding-left: 16px;
                    padding-right: 16px;
                }

                @media(min-width:768px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            @media(min-width:576px) {
                margin-right: 0;
            }

            &.navbar-main-pl {
                >.container-fluid {
                    @media(min-width:992px) {
                        .navbar-brand-container {
                            min-width: 73px;
                        }
                    }

                    .offcanvas {
                        ul.main-menu {
                            >li {
                                a.nav-link {
                                    @media(min-width:992px) {
                                        padding-top: 0;
                                        padding-bottom: 0;

                                        &.active {
                                            &:after {
                                                left: 8px;
                                                right: 8px;
                                                bottom: -1px;
                                            }
                                        }
                                    }

                                    @media(min-width:1012px) {
                                        padding-left: 5px;
                                        padding-right: 5px;
                                    }

                                    @media(min-width:1200px) {
                                        padding-left: 8px;
                                        padding-right: 8px;
                                    }
                                }
                            }

                            @media(min-width:992px) {
                                flex-wrap: wrap;
                            }
                        }
                    }

                }
            }

                        .nav-logo-section {
                            display: none;
                        }

                        @media screen and (min-width: 1125px) {
                            .nav-border-left {
                                border-left: 1px solid #2E4A5E;
                                height: 28px;
                            }

                            .nav-logo-section {
                                display: block;
                                width: 73.81px;
                                height: auto;
                                // background: url('../../assets/images/vottak_logo.svg') no-repeat center center transparent;
                            }

                            .nav-border-right {
                                border-right: 1px solid #2E4A5E;
                                height: 28px;
                                margin-right: 16px;
                            }
                        }
        }
    }
}

body.side-bar-open {
    .top-navbar-container {
        position: relative;
        z-index: 1050;
    }
}

.sub-navbar-container {
    margin-top: $subHeaderMarginTop;
    margin-bottom: $headerMarginBottom;
    background: #fff;

    ul.sub-navbar-menu {
        display: flex;
        max-height: 220px;
        margin: 0 -15px;
        padding: 10px 0;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: start;
        list-style: none;

        >li {
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;

            >a {
                display: block;
                padding: 10px 0;
                color: $grey3;
                font-weight: $fwMediumBold;
                font-size: .875rem;
                line-height: 16px;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 0.05em;

                &:hover {
                    text-decoration: underline;
                }

                &.active {
                    color: $accent2;
                }

                @media(min-width:992px) {
                    text-align: center;
                }
            }
        }

        @media(min-width:650px) {
            flex-direction: row;

            >li {
                width: auto;
            }
        }

        @media(min-width:1120px) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &.sub-menu-right {
            @media(min-width:650px) {
                justify-content: flex-end;
            }

            @media(min-width:1120px) {
                justify-content: flex-end;
            }
        }
    }
}

// --------------------------------------------------Sub Header--------------------------------------------------


.sub-navbar-header {
    margin-bottom: -10px;

    ul.sub-navbar-menu {
        display: flex;
        max-height: 220px;
        margin: 0 -15px;
        padding: 10px 0;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: start;
        list-style: none;

        >li {
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;

            >a {
                display: block;
                padding: 10px 0;
                color: $grey3;
                font-weight: $fwMediumBold;
                font-size: .875rem;
                line-height: 16px;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 0.05em;

                &:hover {
                    text-decoration: underline;
                }

                &.active {
                    color: $accent2;
                }

                @media(min-width:992px) {
                    text-align: center;
                }
            }
        }

        @media(min-width:650px) {
            flex-direction: row;
            margin: 0 -30px;

            >li {
                width: auto;
            }
        }

        @media(min-width:1120px) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &.sub-menu-left {
            @media(min-width:650px) {
                justify-content: flex-start;
            }

            @media(min-width:1120px) {
                justify-content: flex-start;
            }
        }
    }
}


.boxesForBreadCrumbs {

    &__breadcrumb {
        width: 100%;
        padding-bottom: 10px;

        .breadcrumb__list {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .breadcrumb__link {
            font-weight: 600;
            font-size: 15px;
            font-family: $sansPro;
            // line-height: 13px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #AAAAAA;
            text-decoration: none;
            align-items: center;
        }

        .breadcrumb__item:hover .breadcrumb__link {
            color: $accent2;
        }

        .active_link {
            color: $accent2;
        }



        .breadcrumb__item::after {
            padding: 12px;
            content: "";
            background: url('../../assets/images/breadcrumb_arrow.png') no-repeat scroll center center;
        }

        .breadcrumb__item:last-child::after {
            background: none;
        }
    }

}
