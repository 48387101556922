div#search-form-container {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    &:not(.open):not(.static-open){
        display: none;
    }
    &:before{
        position: absolute;
        z-index: 0;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100vw;
        transform: translateX(-50%);
        background: #fff;
        content: '';
    }
    #search-dropdown {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 729px;
        margin: 0 0 0 auto;
        .show-calendar-btn{
            position: relative;
            margin-top: 8px;
            padding-right: 27px;
            border: none;
            background: transparent;
            color: $accent2;
            height: 40px;
            font-size: 0.9375rem;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            line-height: 28px;
            &:after{
                position: absolute;
                top: 50%;
                right: 5px;
                width: 14px;
                height: 7px;
                transform: translateY(-50%);
                background: url('/assets/images/angle_down.svg') no-repeat center center transparent;
                content: '';
            }
            > input{
                position: absolute;
                z-index: 0;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                pointer-events: none;
            }
            @media(min-width:576px){
                margin-top: 0;
            }
        }
        .btn-search{
            min-width: 136px;
            height: 40px;
            font-size: 0.9375rem;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            line-height: 24px;
        }
        .input-search{
          max-width: 484px;
          color: #000;

        }
      .clear-button{
        margin-top: 8px;
        @media(min-width:576px){
          margin-top: 0;
        }
      }
    }
    &.static-open{
        position: relative;
    }
}
.daterangepicker{
    border: none;
    &:before,&:after{
        display: none;
    }
    .ranges{
        li{
            &.active{
                border-right: 2px solid $accent2;
                border-radius: 4px 0px 0px 4px;
                background: #F9F7F7;
                color: $accent2;
            }
        }
        @media(min-width:650px){
            width: auto;
        }
    }
    &.show-ranges.ltr{
        .drp-calendar.left {
            border: none;
        }
    }
    .drp-buttons{
        border-top: none;
    }
    .drp-selected{
        display: none;
    }
    .drp-calendar{
        td.weekend.available:not(.off) {
            color: #F36A6A;
        }
        td.today.available:not(.active) {
            color: $accent2;
        }
        td.in-range {
            background-color: #ffe1c5;
        }
        td.active, .daterangepicker td.active:hover {
            border-color: transparent;
            background-color: $accent2;
            color: #fff;
        }
        @media(min-width:650px){
            &.left{
                clear: none;
            }
        }
    }
}
