.header-line{
    margin: 0;
    padding: 12px 0 25px;
    border-top: 1px solid $grey5;
    color: $accent2;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: $fwMediumBold;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    > span,
    > a{
        display: inline-block;
        padding-bottom: 5px;
        border-bottom: 2px solid $accent2;
        color: inherit;
        text-decoration: none;
        &.header-date{
            display: inline-block;
            margin-left: 12px;
            padding: 0;
            border-bottom: none;
            color: $grey4;
            font-weight: $fwBold;
            font-size: 0.875rem;
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            vertical-align: top;
        }
        &.underline-off{
            border-bottom: none;
        }
    }
    &.head-small{
        padding: 8px 0 24px;
        font-size: 1rem;
        line-height: 20px;
        font-weight: $fwBold;
        letter-spacing: 0.05rem;
    }
}
.red-header{
    padding: 0 0 5px;
    color: $accent1;
    font-weight: $fwMediumBold;
    font-size: 1.25rem;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    > span{
        display: inline-block;
        padding-bottom: 5px;
        border-bottom: 2px solid $accent1;
    }
}
.red-header.application-header{
    margin-top: -4px;
    margin-bottom: 45px;
}
.header-orange{
    padding: 6px;
    border-radius: 4px 4px 0 0;
    background: $accent2;
    color: #fff;
    font-size: 1.125rem;
    font-weight: $fwBold;
    text-align: center;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    a{
        color: inherit;
        text-decoration: none;
    }
}
.header-black{
    margin-top: 28px;
    margin-bottom: 44px;
    color: $black;
    font-weight: $fwMediumBold;
    font-size: 1.25rem;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    a{
        color: inherit;
        text-decoration: none;
    }
}
.header-black-2{
    margin-top: 38px;
    margin-bottom: 17px;
    color: $black;
    font-weight: $fwMediumBold;
    font-size: 1.375rem;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
}
.header-check{
    position: relative;
    margin-bottom: 24px;
    padding-left: 42px;
    color: $grey1;
    font-size: 1.5rem;
    font-weight: $fwMediumBold;
    font-family: $serifPro;
    line-height: 32px;
    &:before{
        position: absolute;
        width: 25px;
        height: 19px;
        left: 1px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/assets/images/check.svg') no-repeat center center transparent;
        content: '';
    }
}
.header-texture{
    margin-top: -16px;
    margin-bottom: 16px;
    padding: 13px;
    color: #fff;
    background-color: $accent1;
    background-image: url('/assets/images/texture.png');
    background-blend-mode: multiply;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 2.25rem;
    font-weight: $fwMediumBold;
    line-height: 38px;
    text-align: center;
}
.header-jail{
    margin-top: $subHeaderMarginTop;
    margin-bottom: $headerMarginBottom;
    padding: 14px 0;
    background: url('/assets/images/jail_bar_bg_xs.png') no-repeat center center $accent1;
    background-blend-mode: multiply;
    background-size: 100% calc(100% + 2px);
    > .container-fluid{
        @media(min-width:682px){
            padding-right: 30px;
        }
        @media(min-width:1200px){
            padding-right: 50px;
        }
        @media(min-width:1280px){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .title{
        margin: 0;
        max-width: 174px;
        color: #fff;
        font-size: 1.75rem;
        font-weight: $fwMediumBold;
        line-height: 28px;
        @media(min-width:992px){
            max-width: 100%;
        }
    }
    .days{
        padding-left: 4px;
        color: $accent1;
        font-size: 1rem;
        font-weight: $fwMediumBold;
        line-height: 16px;
        white-space: nowrap;
        letter-spacing: 0.05em;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            margin-top: 24px;
            line-height: 16px;
            @media(min-width:650px){
                margin-top: 0;
            }
            + li{
                @media(min-width:672px){
                    padding-left: 16px;
                }
            }
            a{
                display: inline-block;
                color: #fff;
                font-weight: $fwMediumBold;
                font-size: 1rem;
                line-height: 16px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                &.link-btn{
                    padding: 6px 16px;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 8px;
                    align-items: center;
                }
            }
        }
        @media(min-width:650px){
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
        }
        @media(min-width:672px){
            justify-content: flex-end;
        }
    }
    @media(min-width:650px){
        padding-left: 0;
        padding-right: 0;
        background-image: url('/assets/images/jail_bar_bg_sm.png');
    }
    @media(min-width:768px){
        padding-left: 16px;
        padding-right: 16px;
    }
    @media(min-width:992px){
        background-image: url('/assets/images/jail_bar_bg_lg.png');
    }
}
.header-war{
    margin-top: $subHeaderMarginTop;
    margin-bottom: $headerMarginBottom;
    padding: 14px 0;
    background: url('/assets/images/war_header_bg_xs.png') no-repeat center center $accent1;
    background-blend-mode: hard-light;
    background-size: 100% calc(100% + 2px);
    > .container-fluid{
        @media(min-width:682px){
            padding-right: 30px;
        }
        @media(min-width:1200px){
            padding-right: 50px;
        }
        @media(min-width:1280px){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    a{
        text-decoration: none;
    }
    .title{
        max-width: 174px;
        margin: 0;
        color: #fff;
        font-size: 2rem;
        font-weight: $fwMediumBold;
        line-height: 28px;
        @media(min-width:992px){
            max-width: 100%;
            font-size: 2.25rem;
        }
    }
    .days{
        padding-left: 4px;
        color: $accent1;
        font-size: 1rem;
        font-weight: $fwMediumBold;
        line-height: 16px;
        white-space: nowrap;
        letter-spacing: 0.05em;
    }
    ul{
        width: fit-content;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        li{
            margin-top: 18px;
            line-height: 16px;
            &:first-child{
                margin-top: 0;
            }
            @media(min-width:650px){
                margin-top: 0;
                + li{
                    padding-left: 16px;
                }
            }
            a{
                display: inline-block;
                color: #fff;
                font-weight: $fwMediumBold;
                font-size: 1rem;
                line-height: 16px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                &.link-btn{
                    padding: 6px 16px;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 8px;
                    align-items: center;
                }
                &.active{
                    color: $accent2;
                }
            }
        }
        @media(min-width:650px){
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;
        }
    }
    @media(min-width:650px){
        padding-left: 0;
        padding-right: 0;
        background-image: url('/assets/images/war_header_bg_sm.png');
    }
    @media(min-width:768px){
        padding-left: 16px;
        padding-right: 16px;
    }
    @media(min-width:992px){
        background-image: url('/assets/images/war_header_bg_lg.png');
    }
}
.header-special-project{
    margin-top: $subHeaderMarginTop;
    margin-bottom: $headerMarginBottom;
    padding: 12px 0;
    background: url('/assets/images/special_background_xs.png') no-repeat center center $accent1;
    background-blend-mode: lighten;
    background-size: 100% 100%;
    text-align: center;
    > span{
        color: $accent1;
        font-size: 0.875rem;
        font-weight: $fwBold;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
    h1{
        margin-bottom: 0;
        color: $surface1;
        font-weight: $fwMediumBold;
        font-size: 1.75rem;
        line-height: 38px;
        @media(min-width:650px){
            font-size: 2.25rem;
        }
    }
    @media(min-width:650px){
        background-image: url('/assets/images/special_background_sm.png');
        background-size: 100% calc(100% + 2px);
    }
    @media(min-width:992px){
        background-image: url('/assets/images/special_background_md.png');
    }
    @media(min-width:1280px){
        background-image: url('/assets/images/special_background_lg.png');
    }
}
.telegram-header{
    min-height: 48px;
    padding: 10px 10px 10px 16px;
    border-radius: 4px;
    background: $surface1;
    color: #fff;
    a{
        color: #fff;
        text-decoration: none;
        &.btn{
            padding: 6px 15px;
            font-size: 1rem;
            line-height: 16px;
            font-weight: 400;
        }
    }
}
.ads-header{
    margin-bottom: 8px;
    color: $surface1;
    font-weight: $fwBold;
    font-size: 0.875rem;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
}