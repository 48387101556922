.form-control {
    width: 100%;
    height: 40px;
    padding: 3px 10px;
    border: 1px solid $grey4;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
}
.contact-image{
    margin-top: -50px;
    margin-bottom: -16px;
}
.form-group{
    margin-bottom: 16px;
    label{
        margin-bottom: 4px;
        color: #000000;
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;
    }
}
.contact-form{
    textarea{
        height: auto;
        min-height: 200px;
    }
    p{
        color: $grey4;
        font-family: $sansPro;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 21px;
        letter-spacing: 0.011em;
    }
}
section.contact-form-section{
    margin-bottom: 37px;
}
.contact-form-container{
    &:after{
        display: block;
        height: 1px;
        margin-top: 37px;
        background: $grey5;
        opacity: 0.25;
        content: '';
    }
}
.contact-info{
    p{
        color: $black;
        font-family: $sansPro;
        font-size: 1rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.011em;
        b{
            font-size: 1.125rem;
            font-weight: $fwBold;
        }
    }
    a{
        font-weight: 400;
    }
}
.input-file-container{
    position: relative;
    input{
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    label{
        position: relative;
        min-width: 136px;
        padding: 3px 8px 3px 28px;
        border: 1px solid $grey3;
        border-radius: 5px;
        color: $grey3;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.03em;
        &:before{
            position: absolute;
            left: 10px;
            top: 50%;
            width: 12px;
            height: 14px;
            transform: translateY(-50%);
            background: url('/assets/images/attachment.svg') no-repeat center center transparent;
            content: '';
        }
    }
}
.error{
    .error-message{
        position: relative;
        padding-left: 24px;
        color: $accent1;
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 12px;
        align-items: center;
        letter-spacing: 0.04em;
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            width: 17px;
            height: 17px;
            background: url('/assets/images/error.svg') no-repeat center center transparent;
            content: '';
        }
    }
}
