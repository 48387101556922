.social-icon{
    position: relative;
    height: 46px;
    padding: 6px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    &:before{
        display: inline-block;
        width: 26px;
        height: 26px;
        background-image: url('/assets/images/social_icons.svg');
        background-repeat: no-repeat;
        background-position: center left;
        background-color: transparent;
        content: '';
    }
    &.social-icon-facebook:before{
        width: 12.5px;
    }
    &.social-icon-instagram:before{
        width: 26px;
        background-position-x: -36.5px;
    }
    &.social-icon-telegram:before{
        width: 26.5px;
        background-position-x: -86px;
    }
    &.social-icon-twitter:before{
        width: 23.5px;
        background-position-x: -136px;
    }
    &.social-icon-youtube:before{
        width: 26px;
        background-position-x: -183px;
    }
    &.social-icon-tiktok:before{
        width: 24px;
        background-position-x: -233px;
    }
}
.app-button{
    .social-icon{
        &::before{
            display: none;
        }
    }
}
.social-icon-single{
    position: relative;
    display: inline-block;
    height: 22px;
    padding: 0px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    &:before{
        display: inline-block;
        width: 26px;
        height: 22px;
        /*background-image: url('/assets/images/social_by.svg');*/
        background-repeat: no-repeat;
        background-position: center left;
        background-color: transparent;
        content: '';
    }
    &.social-icon-facebook:before{
        width: 9.5px;
        background-image: url('/assets/images/socialmedia/facebook.svg');
    }
    &.social-icon-instagram:before{
        width: 17.5px;
        background-image: url('/assets/images/socialmedia/instagram.svg');
    }
    &.social-icon-telegram:before{
        width: 19.5px;
        background-image: url('/assets/images/socialmedia/telegram.svg');
    }
    &.social-icon-twitter:before{
        width: 19px;
        background-image: url('/assets/images/socialmedia/twitter.svg');
    }
    &.social-icon-tiktok:before{
        width: 17.5px;
        background-image: url('/assets/images/socialmedia/tiktok.svg');
    }
    &.social-icon-vk:before{
        width: 24.5px;
        background-image: url('/assets/images/socialmedia/vk.svg');
    }
    &.social-icon-odnoklassniki:before{
        width: 13.5px;
        background-image: url('/assets/images/socialmedia/odnoklassniki.svg');
    }
    &.social-icon-youtube:before{
        width: 21.5px;
        background-image: url('/assets/images/socialmedia/youtube.svg');
    }
    &.social-icon-rss:before{
      width: 25px;
      background-image: url('/assets/images/socialmedia/rss_feed.svg');
    }
}
.belsat-youtube-icon{
    display: block;
    max-width: 88px;
    margin-bottom: 20px;
    color: #000;
    font-size: 0.875rem;
    text-decoration: none;
    &:hover{
        color: #000;
    }
    > * {
        display: block;
        text-align: center;
    }
    > span{
        margin-top: 5px;
    }
    @media(min-width:650px){
        max-width: 100px;
    }
}
.belsat-youtube-icons-row{
    @media(min-width:650px){
        margin-left: -16px;
        margin-right: -16px;
        > div{
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
.youtube-icon{
    display: inline-block;
    width: 25px;
    height: 18px;
    background-image: url('/assets/images/youtube.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    vertical-align: bottom;
}
.youtube-icon-2{
    display: inline-block;
    width: 20px;
    height: 15px;
    background-image: url('/assets/images/youtube-icon-2.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    vertical-align: bottom;
}
.mobile-app-link{
    display: block;
}
.mobile-app-link + .mobile-app-link{
    margin-top: 16px;
}
.social-links{
    display: flex;
    width: 100%;
    margin: 0 -17px;
    padding: 0;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
    li{
        padding-left: 17px;
        padding-right: 17px;
        line-height: 0;
    }
}
.socialmedia-box-lng{
    .socialmedia-box-content{
        > div.socialmedia{
            .title{
                display: inline-block;
                margin-bottom: 0;
                vertical-align: sub;
            }
            .social-links{
                display: inline-flex;
                width: auto;
                margin: 0 0;
                vertical-align: middle;
            }
        }
        .telegram-header{
            a:not(.btn){
                font-weight: $fwBold;
                font-size: 1rem;
                line-height: 16px;
                span{
                    display: block;
                    font-size: 0.885rem;
                    text-align: left;
                    font-weight: 400;
                }
            }
        }
    }
}
.telegram-logo{
    display: inline-block;
    font-weight: $fwBold;
    font-size: 16px;
    line-height: 16px;
    &:before{
        display: inline-block;
        width: 19.5px;
        height: 17.5px;
        margin-right: 8px;
        background: url('/assets/images/telegram-icon.svg') no-repeat center center transparent;
        vertical-align: text-bottom;
        content: '';
    }
}
.socialmedia-box-content{
    > div:not(.telegram-header){
        margin-bottom: 5px;
        padding: 16px;
        border-radius: 4px;
        background: $surface4;
        .title{
            margin-bottom: 16px;
            color: $surface1;
            font-weight: $fwBold;
            font-size: 1rem;
            line-height: 16px;
            .youtube-icon-2{
                margin-right: 5px;
            }
        }
        .content{
            align-items: center;
            > div{
              line-height: normal;
              img{
                  display: block;
              }
            }
            a{
                color: $surface1;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 20px;
                letter-spacing: 0.03em;
                text-decoration: none;
            }
            + .content{
                margin-top: 8px;
            }
        }
    }
}
