.schedule {
    padding: 20px;
    background: #fff;

    &-header {
        margin-bottom: 20px;

        h1 {
            text-align: center;

            @media(min-width:650px) {
                text-align: left;
            }
        }

        .week_range {
            display: flex;
            align-items: center;

            .arrow-left {
                &:before {
                    display: inline-block;
                    width: 20px;
                    height: 18px;
                    background: url(/assets/images/arrow_left.svg) no-repeat center center transparent;
                    background-size: contain;
                    content: '';
                    filter: invert(1);
                }
            }

            .arrow-right {
                &:before {
                    display: inline-block;
                    width: 20px;
                    height: 18px;
                    background: url(/assets/images/arrow_right.svg) no-repeat center center transparent;
                    background-size: contain;
                    content: '';
                    filter: invert(1);
                }
            }

            .week_dates {
                margin: 0 10px;
                padding: 0 0 7px;
                font-size: 1rem;
                line-height: 20px;

                @media(min-width:360px) {
                    font-size: 1rem;
                }
            }
        }
    }

    &-nav {
        padding: 0;

        >.container-fluid {
            padding: 0;

            .nav {
                position: absolute;
                border: 1px solid rgba(0, 0, 0, 0.11);
                background: $surface2;

                .nav-item {
                    display: block;
                    width: 100%;

                    .nav-link {
                        width: 100%;
                        border: none;
                        color: $black;
                        font-family: $sans;
                        font-weight: $fwSemiBold;
                        text-align: left;
                        text-transform: uppercase;

                        span {
                            display: inline-block;
                            margin-left: 10px;
                            color: $grey4;
                            font-weight: 400;
                        }

                        &.active {
                            background: transparent;
                        }
                    }

                    +.nav-item {
                        border-top: 1px solid rgba(0, 0, 0, 0.11);
                    }
                }

                @media(min-width:992px) {
                    position: relative;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
                    width: 100%;

                    .nav-item {
                        flex: 1 0 0%;

                        .nav-link {
                            width: 100%;
                            border-radius: 0;
                            border: 1px solid rgba(0, 0, 0, 0.11);
                            border-bottom: none;
                            color: $black;
                            font-size: 1rem;
                            text-align: center;

                            &.active {
                                color: $accent2;
                                font-weight: $fwBold;
                                background: #fff;
                                border-top: 2px solid $accent2;
                            }

                            >span {
                                display: block;
                                margin: 0;
                                color: $grey4;
                                font-size: .9rem;
                                font-weight: 400;
                            }
                        }

                        +.nav-item {
                            border-top: none;
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            width: 100%;
            border-radius: 0;
            color: $accent2;
            font-family: $sans;
            font-size: 1rem;
            font-weight: $fwBold;
            text-align: left;
            line-height: 27px;

            &:focus,
            &:active,
            &:hover {
                outline: 0;
                box-shadow: none;
            }

            .navbar-toggler-label {
                span {
                    display: inline-block;
                    margin-left: 10px;
                    color: $grey4;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }

            .navbar-toggler-icon {
                float: right;
            }
        }
    }

    &-content {
        padding-top: 40px;
        /*border: 1px solid rgba(0, 0, 0, 0.11);*/
        border-top: none;

        .tab-pane {
            >ul {
                /*display: flex;*/
                /*max-height: 440px;*/
                margin-bottom: -1px;
                padding: 0;
                list-style: none;

                /*            flex-wrap: wrap;
                            flex-direction: column;
                            justify-content: flex-start;*/
                /*                columns: 3;
                                column-gap: 40px;*/
                li {
                    display: flex;
                    /*width: 30%;*/
                    padding: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
                    flex: 1 0 0%;
                    font-family: $sans;
                    &.with-description{
                        cursor: pointer;
                    }
                    .time {
                        color: $accent2;
                        font-size: 1rem;
                        line-height: 24px;
                    }

                    .program {
                        margin-left: 10px;
                        color: $black;
                        font-size: 1rem;
                        line-height: 24px;

                        .schedule-description {
                            font-family: $sansPro;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 21px;
                            letter-spacing: 0.011em;
                            color: #2C2C2C;
                            height: 0;
                            overflow: hidden;
                        }
                    }

                    &.actual {
                        background: $accent2;

                        .time {
                            color: #fff;
                        }

                        .program {
                            color: #fff;
                        }
                    }

                    &.schedule_group {
                        display: block;
                        padding: 0;
                        border-bottom: none;
                        background: $surface2;

                        h5 {
                            margin: 0;
                            padding: 5px 10px;
                            background: $surface1;
                            color: #fff;
                            font-family: $sans;
                            font-size: 1rem;
                            line-height: 24px;
                            letter-spacing: 0.005em;
                            font-weight: $fwSemiBold;
                        }

                        ul {
                            padding: 0;
                            margin: 0;

                            li {
                                width: 100%;
                            }
                        }
                    }
                    &.active{
                        .program {
                            .schedule-description{
                                height: fit-content;
                            }
                        }
                    }
                }

                @media(min-width:650px) {
                    columns: 2;
                    column-gap: 20px;
                }

                @media(min-width:992px) {
                    columns: 3;
                    column-gap: 40px;
                }
            }
        }
    }
}

.schedule-not-found {
    border: 1px solid #e7e7e7;
    border-top: 0;
    padding: 20px;
    text-align: center;
}